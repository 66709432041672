import { subDays } from 'date-fns';
import React, { FC, createContext, useState } from 'react'
import { OrderChannelTypeEnum } from 'src/enums/dashboard';
import { IDashboardContext } from 'src/interfaces/dashboards/context';

export interface DashboardContextProps {
    dashboardContext: IDashboardContext;
    setDashboardContext: React.Dispatch<React.SetStateAction<IDashboardContext>>;
}
export const DashboardContext = createContext<DashboardContextProps>(null);

const DashboardPrivider: FC = ({ children }) => {

    const week = subDays(new Date(), 7);
    week.setHours(0, 0, 0);
  
    const [dashboardContext, setDashboardContext] = useState<IDashboardContext>({
        timespan: {
            from: week,
            to: new Date()
        },
        data: null,
        toggle: {
            netSum: true,
            orderChannel: OrderChannelTypeEnum.Store
        }
    });

return (
    <DashboardContext.Provider value={{ dashboardContext, setDashboardContext }}>
        {children}
    </DashboardContext.Provider>
);
}

export default DashboardPrivider