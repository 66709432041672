import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { reducer as tableReducer } from 'src/slices/table';
import { reducer as cardsReducer } from 'src/slices/cards';
import { reducer as tablesReducer } from 'src/slices/tables';
import { reducer as treesReducer } from 'src/slices/trees';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  table: tableReducer,
  cards: cardsReducer,
  tables: tablesReducer,
  trees: treesReducer,
});

export default rootReducer;
