import { TableHead, TableRow, TableCell, TableSortLabel, Box, Checkbox, TextField, InputAdornment, TablePagination } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { useTranslation } from "react-i18next";
import { RootState, useDispatch, useSelector } from 'src/store';
import {
  setAllFilterHeaders, setFilterHeaders
} from 'src/slices/table';
import { useContext } from "react";
import { SettingsContext } from "src/contexts/SettingsContext";

function CreateTableHead({
  order,
  orderBy,
  headerColumns,
  handleRequestSort,
  hasBulkActionCheckboxes,
  handleSelectAllItems,
  densePadding,
  selectedAllItems = false,
  selectedSomeItems = false,
}) {
  const { t }: { t: any } = useTranslation();
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead >
      <TableRow>
      {(hasBulkActionCheckboxes ?? true) ? <TableCell padding={densePadding ? 'none' : 'checkbox'} >
       <Checkbox
            checked={selectedAllItems}
            indeterminate={selectedSomeItems}
            onChange={handleSelectAllItems}
          /> 
        </TableCell>: null }
        <TableCell align="center" padding={densePadding ? 'none' : 'normal'}>{t('Actions')}</TableCell>
        {headerColumns.map((column) => {
          if (column.shown) {
            return (
              <TableCell align='center' key={column.id} padding={densePadding ? 'none' : 'normal'}>
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}
                >
                  {t(column.id)}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

            )
          };
        })
        }
        
      </TableRow>
    </TableHead>

  );

}
export default CreateTableHead