import { Box, Typography, Button, styled, Avatar, Tooltip, IconButton, Slide, Dialog } from "@mui/material";
import { forwardRef, ReactElement, Ref, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from '@mui/icons-material/Close';
interface IconOnClick {
  title?: string,
  handleClick: () => void

}
const ButtonError = styled(Button)(
  ({ theme }) => `
    background: ${theme.colors.error.main};
    color: ${theme.palette.error.contrastText};

    &:hover {
       background: ${theme.colors.error.dark};
    }
   `
);
const AvatarError = styled(Avatar)(
  ({ theme }) => `
     background-color: ${theme.colors.error.lighter};
     color: ${theme.colors.error.main};
     width: ${theme.spacing(12)};
     height: ${theme.spacing(12)};

     .MuiSvgIcon-root {
       font-size: ${theme.typography.pxToRem(45)};
     }
`
);
function ToolTipButton(props: IconOnClick) {
  const title = props.title;
  const handleClick = props.handleClick;
  const { t }: { t: any } = useTranslation();
  return (


    <Tooltip title={t(title)} arrow>
      <IconButton
        onClick={handleClick}
        //onClick={handleClick}
        color="primary"
      >
        <DeleteTwoToneIcon fontSize="small" />
      </IconButton>
    </Tooltip>

  );
}
const DialogWrapper = styled(Dialog)(
  () => `
     .MuiDialog-paper {
       overflow: visible;
     }
`
);
const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function ConfirmPopUp() {
  const { t }: { t: any } = useTranslation();

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleConfirmDelete = () => {

    setOpenConfirmDelete(true);
  };

  //steg framåt. Nu flytta även knappen till en egen komponent. CHECK
  //NÄSTA steg. Byt namn å gör iconen mer funktionell samt till en atom. 
  const DeleteIcon: IconOnClick = {
    title: "Delete",
    handleClick: handleConfirmDelete
  }
  return (<>
    <ToolTipButton {...DeleteIcon} ></ToolTipButton >
    <DialogWrapper
      open={openConfirmDelete}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={closeConfirmDelete}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={5}
      >
        <AvatarError>
          <CloseIcon />
        </AvatarError>

        <Typography
          align="center"
          sx={{
            pt: 4,
            px: 6
          }}
          variant="h3"
        >
          {t('Do you really want to delete this salesItem')}?
        </Typography>

        <Typography
          align="center"
          sx={{
            pt: 2,
            pb: 4,
            px: 6
          }}
          fontWeight="normal"
          color="text.secondary"
          variant="h4"
        >
          {t("You won't be able to revert after deletion")}
        </Typography>

        <Box>
          <Button
            variant="text"
            size="large"
            sx={{
              mx: 1
            }}
            onClick={closeConfirmDelete}
          >
            {t('Cancel')}
          </Button>
          <ButtonError
            //onClick={handleDeleteCompleted}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
          >
            {t('Delete')}
          </ButtonError>
        </Box>
      </Box>
    </DialogWrapper>
  </>);

}
export default ConfirmPopUp