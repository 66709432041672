import { FC, useState, useEffect } from 'react';
import { styled, Grid, Button, alpha, Checkbox } from '@mui/material';
import { useDispatch } from 'src/store';
import { TreeItem, treeItemClasses, TreeItemProps, TreeView } from '@mui/lab';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProductType } from 'src/models/product_type';
import { OneTreeItemState, setSelectedTreeItems } from 'src/slices/trees';

export interface NestedTreeItemProps extends TreeItemProps {
    treeItems?: NestedTreeItemProps[];
    hasCheckbox?: boolean;
  }
  
  export interface FullTreeProps {
    treeItems?: NestedTreeItemProps[];
    hasCheckboxes?: boolean;
  }
  
  
  export interface TreeSettingProps {
    hasCheckboxes?: boolean;
    id: string;
  }
  
  

  
export function MapProductTypesToTreeItems(productTypes: ProductType[]): NestedTreeItemProps[] {
    let mappedTreeItems = [];
    productTypes?.forEach(productType => {
        const treeItem: NestedTreeItemProps = { nodeId: productType.id, label: productType.name, treeItems: MapProductTypesToTreeItems(productType.subTypes) };
        mappedTreeItems.push(treeItem);
    });
    return mappedTreeItems;
}

export function FlattenProductTypes(productTypes: ProductType[]): ProductType[] {
    let flatProductTypes = [];
    productTypes?.forEach(productType => {
        let cleanedProductType: ProductType = { id: "", name: "" };
        cleanedProductType = Object.assign(cleanedProductType, productType);
        cleanedProductType.subTypes = null;
        flatProductTypes.push(cleanedProductType);
        flatProductTypes = flatProductTypes.concat(FlattenProductTypes(productType.subTypes));

    });
    return flatProductTypes;
}

const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props} ></TreeItem>
))(({ theme }) => ({
    [`.${treeItemClasses.iconContainer}  {
        padding: 16px;
        margin: -4px;
      }
      .${treeItemClasses.selected}{
        background-color: rgba(0,0,0,0);
      }
      & .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },

}));
const TreeItemLabel: FC<{ treeItem?: NestedTreeItemProps, treeSettings?: TreeSettingProps }> = ({ treeItem, treeSettings }) => {
    const checkBoxAdjustedPadding = (treeSettings?.hasCheckboxes ?? true) ? "10px" : "0px";
    return (
        <>
            <Grid>
                <div style={{ float: "left", paddingTop: checkBoxAdjustedPadding }}>{treeItem?.label?.toString() ?? ''}  </div>
                {treeSettings?.hasCheckboxes ? <Checkbox sx={{ float: "right" }}></Checkbox> : null}
            </Grid>
        </>);
}

export const TreeItems: FC<{ allTreeItems: NestedTreeItemProps[], treeSettings?: TreeSettingProps }> = ({ allTreeItems, treeSettings }) => {
    return (
        <>
            {allTreeItems.map((treeItem, i) => (
                <StyledTreeItem key={i} nodeId={treeItem.nodeId} label={<TreeItemLabel treeItem={treeItem} treeSettings={treeSettings} />}>
                    {treeItem.treeItems?.length > 0 ? (<TreeItems allTreeItems={treeItem.treeItems} treeSettings={treeSettings}></TreeItems>) : ""}
                </StyledTreeItem>
            ))}
        </>);
}


function getTreeItemsNodes(treeItems: NestedTreeItemProps[]): string[] {
    let treeItemNodes = [];
    treeItems?.forEach(treeItem => {
        treeItemNodes.push(treeItem.nodeId);
        treeItemNodes = treeItemNodes.concat(getTreeItemsNodes(treeItem.treeItems));
    });
    return treeItemNodes;
}


export function MapTreeItemsToState(treeItems: NestedTreeItemProps[]): OneTreeItemState[] {
    let mappedTreeItemStates = [];
    treeItems?.forEach(treeItem => {
        const treeItemState: OneTreeItemState = { id: treeItem.nodeId, selected: false };
        mappedTreeItemStates.push(treeItemState);
        mappedTreeItemStates = mappedTreeItemStates.concat(MapTreeItemsToState(treeItem.treeItems));
    });
    return mappedTreeItemStates;
}

export const FullTree: FC<{ treeItems: NestedTreeItemProps[], treeSettings?: TreeSettingProps, children?}> = ({ treeItems, treeSettings, children }) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState<string[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const allTreeNodes = getTreeItemsNodes(treeItems);

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        const htmlTarget = event.target as HTMLElement;
        if (htmlTarget.nodeName.toLowerCase() == "svg" || (typeof htmlTarget.className === 'string' ? htmlTarget.className.toLowerCase() : '') == "muitreeitem-iconcontainer") {
            setExpanded(nodeIds);
        }
    };
    const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
        dispatch(setSelectedTreeItems({ id: treeSettings.id, nodeIds: nodeIds }));
        setSelected(nodeIds);
    };
    const handleSelectClick = () => {
        const oldSelected = selected.length === 0 ? allTreeNodes : [];
        setSelected(oldSelected);
        dispatch(setSelectedTreeItems({ id: treeSettings.id, nodeIds: oldSelected }));
    };
    const handleExpandClick = () => {
        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? allTreeNodes : [],
        );
    };
    useEffect(() => {
        setExpanded([treeItems[0]?.nodeId ?? '0']);
    }, []);
    return (
        <>
            <Button onClick={handleExpandClick}>
                {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
            </Button>
            <Button onClick={handleSelectClick}>
                {selected.length === 0 ? 'Select all' : 'Unselect all'}
            </Button>
            <TreeView
                aria-label="customized"
                defaultExpanded={[]}
                defaultCollapseIcon={<ExpandLessIcon />}
                defaultExpandIcon={<ExpandMoreIcon />}
                defaultEndIcon={"-"}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
                multiSelect={true}
                expanded={expanded}
                selected={selected}
                sx={{ height: "100%", minHeight: 200, flexGrow: 1, maxWidth: "100%", overflowY: 'auto' }}
            >

                <TreeItems allTreeItems={treeItems} treeSettings={treeSettings}></TreeItems>
                {children}
            </TreeView>
        </>);
}