import { AxiosRequestConfig } from "axios";
import { ProductCreateRequest } from "src/interfaces/bold/product/product";

export const BASE_URL = process.env.REACT_APP_BOLD_API_BASE_URL;
export const BASE_MOCK_URL = "/api";

export const createUrl = (base, path) => `${base}${path}`;
export const createUrlWithSlug = (base: string, path: string, slug: string | number): string => `${base}${path}${slug}`;

export const authorizationHeaderValue = process.env.REACT_APP_BOLD_API_AUTHENTICATION;

// PRODUCT
export const getProducts = (productTypeId: number, manufId: number, productLineId: number, discontinued: boolean): AxiosRequestConfig => ({
  url: createUrlWithSlug(BASE_URL, "/products", `?productTypeId=${productTypeId}&manufId=${manufId}&productLineId=${productLineId}&discontinued=${discontinued}`),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});

export const getProductById = (id: string): AxiosRequestConfig => ({
  url: createUrlWithSlug(BASE_URL, "/products/", id),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});

export const getProductSubClasses = (productClassId: number): AxiosRequestConfig => ({
  url: createUrl(BASE_URL, `/products/productclasses/${productClassId}/subclasses`),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});

export const getProductClasses = (): AxiosRequestConfig => ({
  url: createUrl(BASE_URL, "/products/productclasses"),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});

export const getProductTypes = (): AxiosRequestConfig => ({
  url: createUrl(BASE_URL, "/products/producttypes"),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});

export const getProductLines = (): AxiosRequestConfig => ({
  url: createUrl(BASE_URL, "/products/productlines"),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});

export const getProductStatuses = (): AxiosRequestConfig => ({
  url: createUrl(BASE_URL, "/products/statuses"),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});

export const searchProducts = (searchKey: string): AxiosRequestConfig => ({
  url: createUrlWithSlug(BASE_URL, "/products/list?searchKey=", searchKey),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});

// POST
export const createProduct = (product: ProductCreateRequest): AxiosRequestConfig => ({
  url: createUrl(BASE_URL, "/products/"),
  method: "POST",
  headers: {
    'Authorization': authorizationHeaderValue
  },
  data: product
});

// PUT
export const updateProduct = (id: number, product: ProductCreateRequest): AxiosRequestConfig => ({
  url: createUrlWithSlug(BASE_URL, "/products/", id),
  method: "PUT",
  headers: {
    'Authorization': authorizationHeaderValue
  },
  data: product
});

//MANUFACTURERS
export const getManufacturers = (): AxiosRequestConfig => ({
  url: createUrl(BASE_URL, "/products/manufacturers"),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});

//SUPPLIERS
export const getSuppliers = (): AxiosRequestConfig => ({
  url: createUrl(BASE_URL, "/products/suppliers"),
  method: "GET",
  headers: {
    'Authorization': authorizationHeaderValue
  }
});
