import {
  Grid,
  Box,
  Typography,
  Divider,
  IconButton,
  Card,
  Collapse,
  CardTypeMap,
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { FC, useState } from 'react';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import AdditionalActions from '../Atoms/Buttons/AdditionalActions';
import TooltipField from '../Atoms/TooltipField';
import useCardState from 'src/hooks/Card/useCardState';
import { customComponent } from 'src/interfaces/customComponent';
import { separateMenuComponents } from 'src/utils/buttons/separateMenuComponents';
import randomId from 'src/utils/randomId';

const defaultColumnSpacing = 0.2;

export enum CardType{
  noHeader,
  collapsable,
  hideable,
  visibleInitally,
}
export interface ContentCardProps {
  cardId?: string;
  content?: any;
  cardTitleText?: string;
  cardSubTitle?: string;
  cardTypes?: CardType[];
  components?: customComponent[];
  contentPadding?: number;
  md?: number;
  tooltipText?: string;
  children?: React.ReactNode;
}

export const ContentCard = ({
  cardId = "cardID-"+randomId(),
  content,
  cardTitleText = cardId,
  cardSubTitle,
  cardTypes,
  tooltipText,
  contentPadding = 2,
  md = 12+defaultColumnSpacing,
  components,
  children
}: ContentCardProps) => {
  const isCollapsable =  cardTypes?.some(cardType =>cardType == CardType.collapsable) ?? false;
  const [collapsed, toggleExpanded] = useState<boolean>(isCollapsable ? !cardTypes?.some(cardType =>cardType == CardType.visibleInitally) ?? true : false);
  const handleCollapseClick = () => toggleExpanded(!collapsed);
  const hideable = cardTypes?.some(cardType =>cardType == CardType.hideable) ?? false;
  const hiddenInitally = !cardTypes?.some(cardType =>cardType == CardType.visibleInitally) ?? hideable ? true:false;
  const [isCardVisible, hideCard] = useCardState(cardId, hideable, hiddenInitally)
  return (
    <Grid item md={md - defaultColumnSpacing} sx={{ display: isCardVisible ? "block" : "none" }} xs={12} pb={0}  >
      <Collapse in={isCardVisible} timeout="auto" unmountOnExit>
        <Box pb={4} >
          <Card >
            <ContentCardHeader
              {...{ cardId, cardTitleText, cardSubTitle, cardTypes, tooltipText, components }}
              handleCollapseClick={handleCollapseClick}
              handleHideClick={hideCard}
              collapsable={isCollapsable}
              hideable={hideable}
              collapsed={collapsed}
              isHidden={!isCardVisible} />
            <Collapse in={!collapsed} timeout="auto" unmountOnExit>
              <Grid sx={{ width: 1 }} container pt={contentPadding}  >
                <Box sx={{ width: 1 }} px={contentPadding} pb={contentPadding + 1} >
                  {content}
                  {children}
                </Box>
              </Grid>
            </Collapse>
          </Card>
        </Box>
      </Collapse>
    </Grid>
  );
}
interface ContentCardHeaderProps extends ContentCardProps {
  handleCollapseClick: Function,
  handleHideClick: Function,
  hideable: boolean,
  collapsable: boolean,
  collapsed: Boolean,
  isHidden: Boolean
}

const ContentCardHeader = ({
  cardId,
  cardTitleText,
  cardSubTitle,
  tooltipText,
  cardTypes,
  handleCollapseClick,
  handleHideClick,
  hideable,
  collapsable,
  collapsed,
  isHidden,
  components
}: ContentCardHeaderProps) => {
  if (!cardTypes?.some(cardType =>cardType == CardType.noHeader)) {
    const [TopRightComponents, MenuItemsComponents] = separateMenuComponents(components);
    return (
      <>
        <Box px={3} py={2} display="flex" alignItems="end" justifyContent="space-between"  >
          <Box>
            <Typography gutterBottom variant="h4"> {cardTitleText} <TooltipField tooltipText={tooltipText}></TooltipField> </Typography>
            <Typography variant="subtitle2">  {cardSubTitle} </Typography>
          </Box>
          <Box display="flex" justifyContent="right"  >
            {TopRightComponents?.map((customComponent, i) => (
              <Grid pl={1} key={i} item>
                {customComponent.component}
              </Grid>
            ))}
            <CollapseButton collapsable={collapsable} handleCollapseClick={handleCollapseClick} collapsed={collapsed} />
            <HideButton handleHideClick={handleHideClick} hiddenCard={hideable} hidden={isHidden} />
            <AdditionalActions >
              {MenuItemsComponents?.length>0 ? MenuItemsComponents?.map((customComponent, i) => (
                <Grid key={i} item>
                  {customComponent.component}
                </Grid>
              )) : null}
            </AdditionalActions>
          </Box>
        </Box>
        <Divider />
      </>);
  }
  return (<></>);
}

const CollapseButton = ({ collapsable, handleCollapseClick, collapsed }: { collapsable: Boolean, handleCollapseClick: Function, collapsed: Boolean }) => {
  if (collapsable) {
    return (
      <IconButton color="primary" onClick={() => handleCollapseClick()} >
        <ExpandMoreTwoToneIcon sx={{ transform: collapsed ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </IconButton>
    );
  } return (<></>);
}

const HideButton = ({
  handleHideClick,
  hiddenCard,
  hidden
}: { handleHideClick: Function, hiddenCard: Boolean, hidden: Boolean }) => {
  if (hiddenCard) {
    return (
      <IconButton color="primary" onClick={() => handleHideClick()} >
        <CloseIcon sx={{ transform: hidden ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </IconButton>
    );
  } return (<></>);
}

export const ContentCardsContainer = ({ contentCards, children }: { contentCards?: ContentCardProps[], children?}) => {
  return (
    <Grid
      sx={{ px: { xs: 2, md: 4 } }}
      container
      direction="row"
      justifyContent="space-between"
      alignContent={'stretch'}
      alignItems="stretch"
      pb={4}
    >
      {contentCards?.map(
        (contentCard) => (<ContentCard key={contentCard.cardId} {...contentCard} />)
      )}
      {React.Children.map(
        children, child => (React.cloneElement(child))
      )}
    </Grid>
  );
}
export default ContentCardsContainer;

export const CardSectionHeader: FC<{ contentCard?: ContentCardProps, children?}> = ({ contentCard, children }) => {
  return (
    <>
      <Grid item xs={12} pb={0}  >
        <Box pb={4} >
          <Typography gutterBottom variant="h3"> {contentCard.cardTitleText ?? contentCard.cardId} </Typography>
          <Typography variant="subtitle2">  {contentCard.cardSubTitle} </Typography>
          {children}
        </Box>
      </Grid>
    </>
  );
}