import PageHeader, { PageHeaderProps } from 'src/components/Organisms/PageHeader';

import Footer from 'src/components/Molecules/Footer/Footer';
import { Box, Button, Card, Grid, IconButton, IconButtonProps, List, ListItem, ListItemButton, ListSubheader, Tooltip, Typography } from '@mui/material';


// Måste lösa id

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ActionButton from 'src/components/Atoms/Buttons/ActionButton';
import { useCallback, useEffect, useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import axios from 'axios';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { t } from 'i18next';
import { ProductTypeProps } from 'src/models/categories';



interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

// Kan flyttas till en egen molekyl
function CategoryInfoCard(categoryValue: string) {

  return (

    <Typography noWrap>
      <Tooltip title={t('View')} arrow>
        <IconButton
          component={RouterLink}
          to={
            `/${location.pathname.split('/')[1]
            }/sales-items/${categoryValue}`
          }
          color="primary"
        >
          <LaunchTwoToneIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {categoryValue}
    </Typography>

  )

}
const pageHeaderData: PageHeaderProps = {
  title: "Categories ",
  subTitle: "View and edit of Categories",
  components: [{component:<ActionButton title="Action Button" icon={<AddTwoToneIcon />} actionUrl="/management/commerce/products/create" />}],
}


const cateogryProductElements: ProductTypeProps = {

  key: 0,
  label: "Bordslampor",
  children: [
    {
      key: 1,
      label: "Dekorationsföremål",
      children: [
        {
          key: 2,
          label: "Kaffekoppar",

        },
        {
          key: 3,
          label: "Godis",
          children: [{
            key: 4,
            label: "Mattallrikar",
          },]
        },

      ]
    },
    {
      key: 4,
      label: "Uppläggningsfat",
    },]
}

interface productTagsFunction {
  items: ProductTypeProps,
  updateSelectedCategory?: (property: string) => void
}
function ProductTypesList(props: productTagsFunction) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleChange = (

    property: string,
  ) => {
    props.updateSelectedCategory(property)

  };
  let values = null;
  if (props.items.children && props.items.children.length) {

    values = (
      <Grid >

        <Grid container spacing={-3}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>


            {props.items.children.map(i => {

              return (<Box key={i.key}> {ProductTypesList({ items: i, updateSelectedCategory: props.updateSelectedCategory })} </Box>);
            })}



          </Collapse>

        </Grid>
      </Grid>


    );
  }

  return (
  <ListSubheader component="ul" disableSticky>
    <Box sx={{ display: "inline-flex", borderBottom: 1, borderLeft: 1 }}>
      <Button color={"inherit"} size={"large"} onClick={() => { handleChange(props.items.label); }}>{props.items.label}
      </Button>
      {props.items.children && props.items.children.length > 0 ? (<IconButton
          aria-label="show more"
          size="small"
          onClick={handleExpandClick}
        >
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
) : (<></>)}

    </Box>
    {values}
  </ListSubheader>
  );
}
function Categories() {

  // Make mock api call here. 
  const [selectedCategory, setSelectedCategory] = useState<string>("all");
  const handleChange = (
    property: string,
  ) => {
    setSelectedCategory(property)

  };
  return (
    <>
      <PageHeader  {...pageHeaderData} />

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="left"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12} md={4}>
          <Card>
              {ProductTypesList({ items: cateogryProductElements, updateSelectedCategory: handleChange })}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            {CategoryInfoCard(selectedCategory)}

          </Card>
        </Grid>
        <Grid item xs={12} md={4}></Grid>

      </Grid>
      <Footer />
    </>
  );
}

export default Categories;

