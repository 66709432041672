import React, { ChangeEvent, useEffect, useState } from 'react';
import { Order } from 'src/enums/general';

export const useTablePageState = (
    limits,
    onePageTable: boolean,
    tableDataLenght): [
        number,
        number,
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number) => void,
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    ] => {
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(limits);
    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };
    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };
    useEffect(() => {
        if (onePageTable ?? false) {
            setLimit(tableDataLenght);
        }
    }, []);

    return [page, limit, handlePageChange, handleLimitChange]
};





export const useTableSearchState = (): [string, Function] => {
    const [nameQuery, setQuery] = useState<string>('');
    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setQuery(event.target.value);
    };

    return [nameQuery, handleQueryChange]
};

export const useTableSelectItemsStates = (id_column: string, tableData: any[]): [number[], Function, Function] => {
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const handleSelectAllItems = (
        event: ChangeEvent<HTMLInputElement>
    ): void => {
        setSelectedItems(
            event.target.checked ? tableData.map((tableItem) => tableItem[id_column]) : []
        );
    };
    const handleSelectOneItem = (
        event: ChangeEvent<HTMLInputElement>,
        id: number
    ): void => {
        if (!selectedItems.includes(id)) {
            setSelectedItems((prevSelected) => [...prevSelected, id]);
        } else {
            setSelectedItems((prevSelected) =>
                prevSelected.filter((id) => id !== id)
            );
        }
    };

    return [selectedItems, handleSelectAllItems, handleSelectOneItem]
};





export const useTableOrderByState = (id_column): [Order, string, Function] => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(id_column);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return [order, orderBy, handleRequestSort]
};