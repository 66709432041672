
import { ManufacturersResponse } from 'src/interfaces/bold/product/manufacturer';
import { ProductsResponse, ProductResponse, ProductTypeResponse } from 'src/interfaces/bold/product/product';
import { mock } from 'src/utils/axios';

let productsResponse: ProductsResponse[] = [
    {
        "ProductsId": "100004",
        "Name": "Senat",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Roman Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2283",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 1,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100005",
        "Name": "Julia",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Roman Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-1995",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 1,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100006",
        "Name": "Kartago",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Roman Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "13416",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 1,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94052050,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100008",
        "Name": "Julianus",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Roman Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "13435",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 1,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94052050,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100072",
        "Name": "Halmstad",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "MS 35",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94059900,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100073",
        "Name": "Estrad",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "MS 81",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100112",
        "Name": "Romb",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "LP-1075",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94059900,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100114",
        "Name": "Parkett",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "LP-1090B",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100116",
        "Name": "Ridå",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "LP-1091",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100117",
        "Name": "Scen",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "LP-1341",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100133",
        "Name": "Pauline",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Bonaparte Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "GEP 7622",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 6,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100244",
        "Name": "Strömsholm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Gustavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "10618",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 3,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94054091,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100245",
        "Name": "Rosersberg",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Gustavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "12228",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 3,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100246",
        "Name": "Hagalund",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Gustavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "10453",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 3,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94054091,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100248",
        "Name": "Josephine",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "LP-1063",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100288",
        "Name": "Colette",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-1354",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100290",
        "Name": "Breton",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-1624",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100292",
        "Name": "Deauville",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-1231",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100293",
        "Name": "Trouiville",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-1295",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100297",
        "Name": "Croisette",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2095",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100299",
        "Name": "Lorraine",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-1656",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100304",
        "Name": "Toulon",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "31998-BLUE",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100601",
        "Name": "Menou",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "MG85TL grey",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100602",
        "Name": "Jérome",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "MG85TL purple",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100603",
        "Name": "Saint-Cloud",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "MG85TL green",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100604",
        "Name": "Skär",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "M101TL brown",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100605",
        "Name": "Udde",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "M101TL black",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100606",
        "Name": "Fyr",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "M101TL teal",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100607",
        "Name": "Grynna",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "M101TL green",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100608",
        "Name": "Kobbe",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "M101TL purple",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100609",
        "Name": "Sund",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "M101TL white",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100610",
        "Name": "Sarek",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "MG67TL blue",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100611",
        "Name": "Rivoli ",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "MG67TL brown",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100612",
        "Name": "Glasklart",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "MG92TL Linen",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "100884",
        "Name": "Johannesört",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Garden Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "61/11L",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 9,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100898",
        "Name": "Phebe",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "82326",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94055000,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100902",
        "Name": "Grenache",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Femme Francaise Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "82846",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 4,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94055000,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100906",
        "Name": "Acle",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Windsor Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "28692",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 7,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100910",
        "Name": "Grassini",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Bonaparte Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "29355",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 6,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94059900,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100912",
        "Name": "Hortense",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Bonaparte Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "29343",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 6,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94059900,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100914",
        "Name": "Foures",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Bonaparte Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "25284",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 6,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100917",
        "Name": "Aintree",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Windsor Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "19730",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 7,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100918",
        "Name": "Beauharnais",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Bonaparte Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "25867",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 6,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100920",
        "Name": "Jerome",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Bonaparte Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "23853",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 6,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94059900,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100921",
        "Name": "Alston",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Windsor Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "27292",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 7,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100922",
        "Name": "Banbury",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Windsor Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "25815",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 7,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100946",
        "Name": "Blekinge",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "45-196-42/cream",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100947",
        "Name": "Bohuslän",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "45-196-42/red",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94059900,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100949",
        "Name": "Dalarna",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "45-196-42/black",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100950",
        "Name": "Gotland",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "42-808-57+FS1717-27 BLK",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100951",
        "Name": "Gästrikland",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "42-808-57+FS1717-27 CRE",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100952",
        "Name": "Carlisle",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Windsor Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2712",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 7,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100953",
        "Name": "Derby",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Windsor Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2404",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 7,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100954",
        "Name": "Delamere",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Windsor Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2788",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 7,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100955",
        "Name": "Eagle",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Windsor Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2527",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 7,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100956",
        "Name": "Eastbourne",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Windsor Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-1624/Rust Iron",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 7,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100961",
        "Name": "Alnarp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Garden Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2546",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 9,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100964",
        "Name": "Baldersnäs",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Garden Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2720",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 9,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": false,
        "IsCustomerSpecific": false,
        "TARIC": 94059900,
        "CountryOfOriginId": 21
    },
    {
        "ProductsId": "100965",
        "Name": "Bergianska",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Garden Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2726",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 9,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "100966",
        "Name": "Bosjökloster",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Garden Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "RL-2731",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 9,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94059900,
        "CountryOfOriginId": 104
    },
    {
        "ProductsId": "101091",
        "Name": "Vreta",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Seaside Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "JFL2011-075",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 10,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "101092",
        "Name": "Rindö",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Seaside Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "JFL2011-074",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 10,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "101093",
        "Name": "Furusund",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Seaside Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "JFL2011-081",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 10,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "101094",
        "Name": "Tamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Seaside Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "JFL2011-071",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 10,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "101095",
        "Name": "Barbord",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Seaside Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "JFL2011-072",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 10,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "101096",
        "Name": "Styrbord",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Seaside Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "JFL2011-082",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 10,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "101097",
        "Name": "Boj",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Seaside Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "JFL2011-080",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 10,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "101098",
        "Name": "Fender",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Seaside Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "JFL2011-069",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 10,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 48
    },
    {
        "ProductsId": "101153",
        "Name": "Lax",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "t/study/Green",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 165
    },
    {
        "ProductsId": "101154",
        "Name": "Lodjur",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "t/study/Orange",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 165
    },
    {
        "ProductsId": "101155",
        "Name": "Brunbjörn",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "t/study/Dark Beige",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 165
    },
    {
        "ProductsId": "101156",
        "Name": "Älg",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": "The Scandinavian Line",
        "MfgName": "Oscar & Clothilde",
        "ManufacturerProductId": "t/study/Black",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 4,
        "ProductLineId": 2,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 9405409100,
        "CountryOfOriginId": 165
    },
    {
        "ProductsId": "101357",
        "Name": "Lamp Dèglise pm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Ateliers C.S.D",
        "ManufacturerProductId": "OD0011",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 206,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101412",
        "Name": "Lampa",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Becara",
        "ManufacturerProductId": "662062",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 209,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101413",
        "Name": "Lampa",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Becara",
        "ManufacturerProductId": "650290",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 209,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101446",
        "Name": "Lampe Ethan",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Blanc Dívoire",
        "ManufacturerProductId": "TLETHAN",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 210,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101447",
        "Name": "Lampe JONAS",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Blanc Dívoire",
        "ManufacturerProductId": "TLJONAS",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 210,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101482",
        "Name": "Lampe Chapo Simple - Orange Int.",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Céjolie",
        "ManufacturerProductId": "2390",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 215,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101483",
        "Name": "Lampe Chapo Simple - rouge",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Céjolie",
        "ManufacturerProductId": "2391",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 215,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101484",
        "Name": "Lampe Chapo Simple - vert",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Céjolie",
        "ManufacturerProductId": "2392",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 215,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101485",
        "Name": "Lampe Trompette - Orange Int.",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Céjolie",
        "ManufacturerProductId": "2393",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 215,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101486",
        "Name": "Lampe Trompette - Rouge Int.",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Céjolie",
        "ManufacturerProductId": "2395",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 215,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101487",
        "Name": "Lampe trompette - Vert Int",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Céjolie",
        "ManufacturerProductId": "2394",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 215,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101499",
        "Name": "Aubergine Base lamp with relief",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Ceramica ND DOLFI",
        "ManufacturerProductId": "L-B09PGVER",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 217,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 109
    },
    {
        "ProductsId": "101678",
        "Name": "Lamp table brunswick ant",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Eichholtz",
        "ManufacturerProductId": "6625",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 234,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101679",
        "Name": "Lamp table brunswick silver",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Eichholtz",
        "ManufacturerProductId": "6623",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 234,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101680",
        "Name": "Lamp table galvin ink skärm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Eichholtz",
        "ManufacturerProductId": "LIG07336",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 234,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 165
    },
    {
        "ProductsId": "101725",
        "Name": "Bay Gourd glass lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "GLB27",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101727",
        "Name": "Duck feet lamp decayed gold fot",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "VLB20 DCG",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 196
    },
    {
        "ProductsId": "101729",
        "Name": "Giraffe lamp decayed gold",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "SLB-54",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101730",
        "Name": "Gourd glass lamp black",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "GLB27-BLK-CLR",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101731",
        "Name": "Gourd glass lamp prune ex.skärm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "GLB27-PRU-CLR",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101732",
        "Name": "Gourd glass lampe saffron",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "GLB27-SAF-CLR",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101738",
        "Name": "Organic loop lamp nickel",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "2402",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 196
    },
    {
        "ProductsId": "101747",
        "Name": "Porta Romana Art lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "SLB38",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101749",
        "Name": "Porta Romana floorlamp french brass",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "MFL20",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101750",
        "Name": "Porta Romana Motu lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "2412",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101751",
        "Name": "Porta Romana Organic Loop Lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "2408",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101752",
        "Name": "Organic Loop Wall light ink skärm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "TWL10",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101753",
        "Name": "Porta Romana Small Lollipop burn gold",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "2417",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101754",
        "Name": "Porta Romana Small Rameau",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "2415",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101755",
        "Name": "Porta Romana Twig Table Lamp gold",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "2410",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101759",
        "Name": "Tapering Harral Lamp, Small - French Brass",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Frank & Cordinata",
        "ManufacturerProductId": "SLB37S-FRB",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 242,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 196
    },
    {
        "ProductsId": "101769",
        "Name": "Lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "G & C Interiors A/S",
        "ManufacturerProductId": "IN/PA/69377/NI/",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 244,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101770",
        "Name": "Lamp 1519, Tall, ant brass",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "G & C Interiors A/S",
        "ManufacturerProductId": "TH/CD1519/ART/L",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 244,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 58
    },
    {
        "ProductsId": "101771",
        "Name": "Lamp 2348",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "G & C Interiors A/S",
        "ManufacturerProductId": "TH/CD2348/B/L",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 244,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101772",
        "Name": "Lamp1015 Ant.Silver",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "G & C Interiors A/S",
        "ManufacturerProductId": "TH/CD1015/DARKB/AS/L",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 244,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 58
    },
    {
        "ProductsId": "101773",
        "Name": "Lamp lined black/mässing",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "G & C Interiors A/S",
        "ManufacturerProductId": "TH/CD2338/ART/B/L",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 244,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 58
    },
    {
        "ProductsId": "101774",
        "Name": "Lamp mässing/marmor1462",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "G & C Interiors A/S",
        "ManufacturerProductId": "TH/CD1462",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 244,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101775",
        "Name": "Lamp Rings brown",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "G & C Interiors A/S",
        "ManufacturerProductId": "TH/CD2350",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 244,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 58
    },
    {
        "ProductsId": "101777",
        "Name": "Lamp, Tall multicolor Yellow top",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "G & C Interiors A/S",
        "ManufacturerProductId": "CH/LE496/CL2361",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 244,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101778",
        "Name": "Lampa mässing marmor",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "G & C Interiors A/S",
        "ManufacturerProductId": "TH/CD1045",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 244,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101823",
        "Name": "Giraffe lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Inside too",
        "ManufacturerProductId": "JA-7265",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 250,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101832",
        "Name": "Staffordshire spaniel lamp rigth",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Inside too",
        "ManufacturerProductId": "JA-7162",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 250,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101833",
        "Name": "Stafforshire spaniel lamp left",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Inside too",
        "ManufacturerProductId": "JA-7160",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 250,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101903",
        "Name": "Lampe sur pied Vivaro",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Labyrinthe Interiors",
        "ManufacturerProductId": "841",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 257,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "101933",
        "Name": "Lampa",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "18DIAB",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101934",
        "Name": "Lampa bel air",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "33BRA",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101935",
        "Name": "Lampe Arabis",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "17ARA",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101936",
        "Name": "Lampe arbre",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "14ARBPM",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101937",
        "Name": "Lampe Bel Air",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "15BEL",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "101938",
        "Name": "Lampe Brescia",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "17BRE",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101939",
        "Name": "Lampe flambe, Noir mat",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "15FLBS",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101940",
        "Name": "Lampe Hedera",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "17HED",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101941",
        "Name": "Lampe iris",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "17IRI",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101942",
        "Name": "Lampe Ivy",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "17IVY",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101943",
        "Name": "Lampe Roma",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "AJERC35T",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101944",
        "Name": "Lampe Tulipa",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "les comptoirs du Sud",
        "ManufacturerProductId": "17TYL",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 260,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "101982",
        "Name": "Stor lampett tenn 29 cm.",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Malin Appelgren Paulsson",
        "ManufacturerProductId": "stor.lampett.tenn",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 266,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 83062900,
        "CountryOfOriginId": 196
    },
    {
        "ProductsId": "101983",
        "Name": "Stor lampett mässing 29 cm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Malin Appelgren Paulsson",
        "ManufacturerProductId": "stor.lampett.29cm",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 266,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": 94055000,
        "CountryOfOriginId": 196
    },
    {
        "ProductsId": "102017",
        "Name": "Candeeiro CD 2456",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "I0213177",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102032",
        "Name": "Floor lamp camargue Zinnia",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XCA303000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102043",
        "Name": "Lamp Alexandrie",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "LALE4000-CE",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "102046",
        "Name": "Lamp Camargue Artichaut bordslampa",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "LCAG4040-CE",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "102049",
        "Name": "Lamp Colonne Periere",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XPR4025",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102050",
        "Name": "Lamp Nancy",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XNCY400000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102051",
        "Name": "Lamp Nest Golden",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XNID400000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "102052",
        "Name": "Lamp Opera Yellow",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XOP401100",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102053",
        "Name": "Lamp Sègur",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XSEG402000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "102054",
        "Name": "Lamp Seville wood",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XSV402000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102056",
        "Name": "Lampa Victoria L",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XVCT401000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102057",
        "Name": "Lampa Victoria S",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XVCT400000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102058",
        "Name": "Lampadaire Sègur GM",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XSEG302000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102059",
        "Name": "Lampe autun",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XAT400000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "102060",
        "Name": "Lamp Camargue Marguerite",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "LCAG4050-CE",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "102061",
        "Name": "Lampe chene brass",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XCHN401000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "102063",
        "Name": "Lampe Feuille de Chene",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XCHN402000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102064",
        "Name": "Lampe Gris-bleute",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XOP401200",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102065",
        "Name": "Lampe insolite orsay",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XIN403000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102066",
        "Name": "Lampe Iris GM",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XIR402000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102067",
        "Name": "Lampe Iris GM",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XIR4020",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102068",
        "Name": "Lampe Jonc des Marais",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "LJON4080-CE",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "102070",
        "Name": "Lampe Marronier",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XMR400000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102071",
        "Name": "Lamp Monceau CE",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "LMOC40000-CE",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 74
    },
    {
        "ProductsId": "102072",
        "Name": "Lampe Opera blue",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XOP401300",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102074",
        "Name": "Lampe Oran",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XORA401000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102075",
        "Name": "Lampe Seville glass/brass",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XSV402500",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102078",
        "Name": "lampe Vienne verre GM",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XVN4070-00",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102115",
        "Name": "Sconce Camargue Zinnia",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Mis en Demeure Decoration",
        "ManufacturerProductId": "XCA101000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 269,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102161",
        "Name": "Lampadaire de table",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Objet de Curiosite",
        "ManufacturerProductId": "LU083",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 272,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102162",
        "Name": "Lampe à poser tubulaire",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Objet de Curiosite",
        "ManufacturerProductId": "LU127",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 272,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102163",
        "Name": "Lampe articulèe",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Objet de Curiosite",
        "ManufacturerProductId": "LU014",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 272,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102164",
        "Name": "Lampe avec reflecteur",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Objet de Curiosite",
        "ManufacturerProductId": "LU130",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 272,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102165",
        "Name": "Lampe de bureau laiton à crèmaillaère",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Objet de Curiosite",
        "ManufacturerProductId": "LU051",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 272,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102313",
        "Name": "Venetiansk taklampa Orrefors",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Stadsauktion, Frihamnen",
        "ManufacturerProductId": "143338",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 289,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102333",
        "Name": "Andos adjustable desk light ink.skärm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Tierlantijn BV",
        "ManufacturerProductId": "L.079.9.820",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 293,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 165
    },
    {
        "ProductsId": "102334",
        "Name": "Ballano adjust. standard lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Tierlantijn BV",
        "ManufacturerProductId": "L.116.9.820",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 293,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 165
    },
    {
        "ProductsId": "102337",
        "Name": "Delphi adjustable table lamp bronze",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Tierlantijn BV",
        "ManufacturerProductId": "L.050.1.820",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 293,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 165
    },
    {
        "ProductsId": "102351",
        "Name": "2012-2010/Col.Point Table Lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "UMOS",
        "ManufacturerProductId": "2012101000009",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 294,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102355",
        "Name": "Lamp N. York Gold",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "UMOS",
        "ManufacturerProductId": "1003210000000",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 294,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102392",
        "Name": "Pisa floor lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Vaughan Limited",
        "ManufacturerProductId": "OSL39/BZ",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 296,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 76
    },
    {
        "ProductsId": "102394",
        "Name": "Pisa floor lamp bronze",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Vaughan Limited",
        "ManufacturerProductId": "BL39 BZ",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 296,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102395",
        "Name": "Positano Alabaster lamp brass",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Vaughan Limited",
        "ManufacturerProductId": "TA0008.BR",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Active",
        "ProductStatusId": 1,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 296,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": true,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 76
    },
    {
        "ProductsId": "102404",
        "Name": "Twig Standard lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Vaughan Limited",
        "ManufacturerProductId": "OSL29/BZ",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 296,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102405",
        "Name": "Twig table lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Vaughan Limited",
        "ManufacturerProductId": "OBR58/BR",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 296,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102406",
        "Name": "Woodville table lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Vaughan Limited",
        "ManufacturerProductId": "OBR79/N",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 296,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102431",
        "Name": "Glass Table Lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Zhengzhou Homepaint/woodcarvin",
        "ManufacturerProductId": "HL12021",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 301,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102432",
        "Name": "Glass Table Lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Zhengzhou Homepaint/woodcarvin",
        "ManufacturerProductId": "HL12019",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 301,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102433",
        "Name": "Glass Table Lamp - red wire",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Zhengzhou Homepaint/woodcarvin",
        "ManufacturerProductId": "HL12028",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 301,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102434",
        "Name": "Glass Table Lamp - red wire",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Zhengzhou Homepaint/woodcarvin",
        "ManufacturerProductId": "HL12032",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 301,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102478",
        "Name": "Wacko floor lamp",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "BASANT",
        "ManufacturerProductId": "BT0785T",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 304,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102499",
        "Name": "Lampglob",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Antique Stef",
        "ManufacturerProductId": "D38",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 361,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102501",
        "Name": "2 Lampes Colonnes 1 par",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Antiquités Lahaye",
        "ManufacturerProductId": "Antiquités Lahaye-2693",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 362,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102502",
        "Name": "Lampe colonnes á jene",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Antiquités Lahaye",
        "ManufacturerProductId": "AL/2",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 362,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102521",
        "Name": "Lampe Devidoir a Laine ink. skärm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Ateliers C.S.D",
        "ManufacturerProductId": "OD033",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 206,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102522",
        "Name": "Lampe Miroir ink. skärm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Ateliers C.S.D",
        "ManufacturerProductId": "OD031",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 206,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102646",
        "Name": "Dark green base lampe with dots",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Ceramica ND DOLFI",
        "ManufacturerProductId": "L-B09SEM",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 217,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": 109
    },
    {
        "ProductsId": "102649",
        "Name": "Lamp base green with purple/",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Ceramica ND DOLFI",
        "ManufacturerProductId": "L-B09FUCS",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 217,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102673",
        "Name": "Lampa inkl skärm",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Cleo Burtin",
        "ManufacturerProductId": "Cleo Burtin-3217",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 367,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102686",
        "Name": "Chicago floor lamp Black",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "f/ch/5x3B",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102687",
        "Name": "Chicago floor lamp Orange",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "f/ch/5x3O",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102688",
        "Name": "Chicago table lamp black",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "t/ch/2x3B",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102689",
        "Name": "Chicago table lamp Orange",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "t/ch/2x3O",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102690",
        "Name": "Chicago wall lamp Black",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "w/ch/2x3B",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102691",
        "Name": "Chicago wall lamp Orange",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "w/ch/2x3O",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102692",
        "Name": "Double dip hanging lamp Black",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "h/dd/bB",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102693",
        "Name": "Double dip hanging lamp Dark beige",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "h/dd/bDB",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102694",
        "Name": "Double dip hanging lamp Green",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "h/dd/bG",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102695",
        "Name": "Double dip hanging lamp Orange",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "h/dd/bO",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    },
    {
        "ProductsId": "102696",
        "Name": "Floor lamp indu small hat",
        "ProductTypesId": 36,
        "ProductType": "Lamps",
        "InformationRequired": "Completed",
        "ProductLine": null,
        "MfgName": "Door to Door",
        "ManufacturerProductId": "f/indu/1",
        "ValidFromDate": new Date(2013 - 10 - 30),
        "ValidToDate": null,
        "ProductCl": "Warehouse Items",
        ProductSubCl: null,
        "ProductStatus": "Inactive",
        "ProductStatusId": 2,
        "RequiredProperties": 0,
        "RequiredPropertiesEntered": 0,
        "ManufacturerPartnerId": 230,
        "ProductLineId": 0,
        "VariantCount": 0,
        "IsPhysical": false,
        "IsDigital": null,
        "IsCustomerSpecific": false,
        "TARIC": null,
        "CountryOfOriginId": null
    }
]

let products: ProductResponse[] = [
    {
        "ProductId": "100004",
        "Name": "Senat",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2283",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100005",
        "Name": "Julia",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-1995",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100006",
        "Name": "Kartago",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "13416",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94052050',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100008",
        "Name": "Julianus",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "13435",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94052050',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100072",
        "Name": "Halmstad",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "MS 35",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94059900',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100073",
        "Name": "Estrad",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "MS 81",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100112",
        "Name": "Romb",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LP-1075",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94059900',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100114",
        "Name": "Parkett",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LP-1090B",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100116",
        "Name": "Ridå",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LP-1091",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100117",
        "Name": "Scen",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LP-1341",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100133",
        "Name": "Pauline",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "GEP 7622",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100244",
        "Name": "Strömsholm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "10618",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94054091',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100245",
        "Name": "Rosersberg",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "12228",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100246",
        "Name": "Hagalund",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "10453",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94054091',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100248",
        "Name": "Josephine",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LP-1063",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100288",
        "Name": "Colette",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-1354",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100290",
        "Name": "Breton",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-1624",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100292",
        "Name": "Deauville",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-1231",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100293",
        "Name": "Trouiville",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-1295",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100297",
        "Name": "Croisette",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2095",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100299",
        "Name": "Lorraine",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-1656",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100304",
        "Name": "Toulon",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "31998-BLUE",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100601",
        "Name": "Menou",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "MG85TL grey",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100602",
        "Name": "Jérome",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "MG85TL purple",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100603",
        "Name": "Saint-Cloud",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "MG85TL green",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100604",
        "Name": "Skär",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "M101TL brown",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100605",
        "Name": "Udde",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "M101TL black",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100606",
        "Name": "Fyr",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "M101TL teal",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100607",
        "Name": "Grynna",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "M101TL green",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100608",
        "Name": "Kobbe",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "M101TL purple",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100609",
        "Name": "Sund",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "M101TL white",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100610",
        "Name": "Sarek",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "MG67TL blue",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100611",
        "Name": "Rivoli ",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "MG67TL brown",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100612",
        "Name": "Glasklart",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "MG92TL Linen",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100884",
        "Name": "Johannesört",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "61/11L",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100898",
        "Name": "Phebe",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "82326",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94055000',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100902",
        "Name": "Grenache",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "82846",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94055000',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100906",
        "Name": "Acle",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "28692",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100910",
        "Name": "Grassini",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "29355",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94059900',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100912",
        "Name": "Hortense",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "29343",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94059900',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100914",
        "Name": "Foures",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "25284",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100917",
        "Name": "Aintree",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "19730",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100918",
        "Name": "Beauharnais",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "25867",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100920",
        "Name": "Jerome",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "23853",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94059900',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100921",
        "Name": "Alston",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "27292",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100922",
        "Name": "Banbury",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "25815",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100946",
        "Name": "Blekinge",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "45-196-42/cream",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100947",
        "Name": "Bohuslän",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "45-196-42/red",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94059900',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100949",
        "Name": "Dalarna",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "45-196-42/black",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100950",
        "Name": "Gotland",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "42-808-57+FS1717-27 BLK",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100951",
        "Name": "Gästrikland",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "42-808-57+FS1717-27 CRE",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100952",
        "Name": "Carlisle",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2712",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100953",
        "Name": "Derby",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2404",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100954",
        "Name": "Delamere",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2788",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100955",
        "Name": "Eagle",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2527",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100956",
        "Name": "Eastbourne",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-1624/Rust Iron",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100961",
        "Name": "Alnarp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2546",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100964",
        "Name": "Baldersnäs",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2720",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": false,
        "TaricCode": '94059900',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100965",
        "Name": "Bergianska",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2726",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "100966",
        "Name": "Bosjökloster",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "RL-2731",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94059900',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101091",
        "Name": "Vreta",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JFL2011-075",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101092",
        "Name": "Rindö",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JFL2011-074",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101093",
        "Name": "Furusund",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JFL2011-081",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101094",
        "Name": "Tamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JFL2011-071",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101095",
        "Name": "Barbord",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JFL2011-072",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101096",
        "Name": "Styrbord",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JFL2011-082",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101097",
        "Name": "Boj",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JFL2011-080",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101098",
        "Name": "Fender",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JFL2011-069",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101153",
        "Name": "Lax",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "t/study/Green",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101154",
        "Name": "Lodjur",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "t/study/Orange",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101155",
        "Name": "Brunbjörn",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "t/study/Dark Beige",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101156",
        "Name": "Älg",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "t/study/Black",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '9405409100',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101357",
        "Name": "Lamp Dèglise pm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "OD0011",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101412",
        "Name": "Lampa",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "662062",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101413",
        "Name": "Lampa",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "650290",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101446",
        "Name": "Lampe Ethan",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TLETHAN",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101447",
        "Name": "Lampe JONAS",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TLJONAS",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101482",
        "Name": "Lampe Chapo Simple - Orange Int.",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2390",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101483",
        "Name": "Lampe Chapo Simple - rouge",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2391",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101484",
        "Name": "Lampe Chapo Simple - vert",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2392",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101485",
        "Name": "Lampe Trompette - Orange Int.",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2393",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101486",
        "Name": "Lampe Trompette - Rouge Int.",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2395",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101487",
        "Name": "Lampe trompette - Vert Int",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2394",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101499",
        "Name": "Aubergine Base lamp with relief",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "L-B09PGVER",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101678",
        "Name": "Lamp table brunswick ant",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "6625",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101679",
        "Name": "Lamp table brunswick silver",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "6623",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101680",
        "Name": "Lamp table galvin ink skärm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LIG07336",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101725",
        "Name": "Bay Gourd glass lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "GLB27",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101727",
        "Name": "Duck feet lamp decayed gold fot",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "VLB20 DCG",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101729",
        "Name": "Giraffe lamp decayed gold",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "SLB-54",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101730",
        "Name": "Gourd glass lamp black",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "GLB27-BLK-CLR",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101731",
        "Name": "Gourd glass lamp prune ex.skärm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "GLB27-PRU-CLR",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101732",
        "Name": "Gourd glass lampe saffron",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "GLB27-SAF-CLR",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101738",
        "Name": "Organic loop lamp nickel",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2402",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101747",
        "Name": "Porta Romana Art lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "SLB38",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101749",
        "Name": "Porta Romana floorlamp french brass",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "MFL20",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101750",
        "Name": "Porta Romana Motu lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2412",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101751",
        "Name": "Porta Romana Organic Loop Lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2408",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101752",
        "Name": "Organic Loop Wall light ink skärm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TWL10",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101753",
        "Name": "Porta Romana Small Lollipop burn gold",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2417",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101754",
        "Name": "Porta Romana Small Rameau",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2415",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101755",
        "Name": "Porta Romana Twig Table Lamp gold",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2410",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101759",
        "Name": "Tapering Harral Lamp, Small - French Brass",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "SLB37S-FRB",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101769",
        "Name": "Lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "IN/PA/69377/NI/",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101770",
        "Name": "Lamp 1519, Tall, ant brass",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TH/CD1519/ART/L",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101771",
        "Name": "Lamp 2348",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TH/CD2348/B/L",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101772",
        "Name": "Lamp1015 Ant.Silver",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TH/CD1015/DARKB/AS/L",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101773",
        "Name": "Lamp lined black/mässing",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TH/CD2338/ART/B/L",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101774",
        "Name": "Lamp mässing/marmor1462",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TH/CD1462",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101775",
        "Name": "Lamp Rings brown",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TH/CD2350",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101777",
        "Name": "Lamp, Tall multicolor Yellow top",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "CH/LE496/CL2361",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101778",
        "Name": "Lampa mässing marmor",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TH/CD1045",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101823",
        "Name": "Giraffe lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JA-7265",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101832",
        "Name": "Staffordshire spaniel lamp rigth",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JA-7162",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101833",
        "Name": "Stafforshire spaniel lamp left",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "JA-7160",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101903",
        "Name": "Lampe sur pied Vivaro",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "841",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101933",
        "Name": "Lampa",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "18DIAB",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101934",
        "Name": "Lampa bel air",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "33BRA",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101935",
        "Name": "Lampe Arabis",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "17ARA",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101936",
        "Name": "Lampe arbre",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "14ARBPM",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101937",
        "Name": "Lampe Bel Air",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "15BEL",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101938",
        "Name": "Lampe Brescia",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "17BRE",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101939",
        "Name": "Lampe flambe, Noir mat",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "15FLBS",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101940",
        "Name": "Lampe Hedera",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "17HED",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101941",
        "Name": "Lampe iris",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "17IRI",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101942",
        "Name": "Lampe Ivy",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "17IVY",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101943",
        "Name": "Lampe Roma",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "AJERC35T",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101944",
        "Name": "Lampe Tulipa",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "17TYL",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101982",
        "Name": "Stor lampett tenn 29 cm.",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "stor.lampett.tenn",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '83062900',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "101983",
        "Name": "Stor lampett mässing 29 cm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "stor.lampett.29cm",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '94055000',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102017",
        "Name": "Candeeiro CD 2456",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "I0213177",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102032",
        "Name": "Floor lamp camargue Zinnia",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XCA303000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102043",
        "Name": "Lamp Alexandrie",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LALE4000-CE",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102046",
        "Name": "Lamp Camargue Artichaut bordslampa",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LCAG4040-CE",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102049",
        "Name": "Lamp Colonne Periere",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XPR4025",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102050",
        "Name": "Lamp Nancy",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XNCY400000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102051",
        "Name": "Lamp Nest Golden",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XNID400000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102052",
        "Name": "Lamp Opera Yellow",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XOP401100",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102053",
        "Name": "Lamp Sègur",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XSEG402000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102054",
        "Name": "Lamp Seville wood",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XSV402000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102056",
        "Name": "Lampa Victoria L",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XVCT401000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102057",
        "Name": "Lampa Victoria S",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XVCT400000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102058",
        "Name": "Lampadaire Sègur GM",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XSEG302000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102059",
        "Name": "Lampe autun",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XAT400000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102060",
        "Name": "Lamp Camargue Marguerite",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LCAG4050-CE",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102061",
        "Name": "Lampe chene brass",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XCHN401000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102063",
        "Name": "Lampe Feuille de Chene",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XCHN402000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102064",
        "Name": "Lampe Gris-bleute",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XOP401200",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102065",
        "Name": "Lampe insolite orsay",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XIN403000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102066",
        "Name": "Lampe Iris GM",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XIR402000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102067",
        "Name": "Lampe Iris GM",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XIR4020",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102068",
        "Name": "Lampe Jonc des Marais",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LJON4080-CE",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102070",
        "Name": "Lampe Marronier",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XMR400000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102071",
        "Name": "Lamp Monceau CE",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LMOC40000-CE",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102072",
        "Name": "Lampe Opera blue",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XOP401300",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102074",
        "Name": "Lampe Oran",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XORA401000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102075",
        "Name": "Lampe Seville glass/brass",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XSV402500",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102078",
        "Name": "lampe Vienne verre GM",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XVN4070-00",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102115",
        "Name": "Sconce Camargue Zinnia",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "XCA101000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102161",
        "Name": "Lampadaire de table",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LU083",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102162",
        "Name": "Lampe à poser tubulaire",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LU127",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102163",
        "Name": "Lampe articulèe",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LU014",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102164",
        "Name": "Lampe avec reflecteur",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LU130",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102165",
        "Name": "Lampe de bureau laiton à crèmaillaère",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "LU051",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102313",
        "Name": "Venetiansk taklampa Orrefors",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "143338",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102333",
        "Name": "Andos adjustable desk light ink.skärm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "L.079.9.820",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102334",
        "Name": "Ballano adjust. standard lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "L.116.9.820",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102337",
        "Name": "Delphi adjustable table lamp bronze",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "L.050.1.820",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102351",
        "Name": "2012-2010/Col.Point Table Lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "2012101000009",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102355",
        "Name": "Lamp N. York Gold",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "1003210000000",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102392",
        "Name": "Pisa floor lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "OSL39/BZ",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102394",
        "Name": "Pisa floor lamp bronze",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "BL39 BZ",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102395",
        "Name": "Positano Alabaster lamp brass",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "TA0008.BR",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": true,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102404",
        "Name": "Twig Standard lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "OSL29/BZ",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102405",
        "Name": "Twig table lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "OBR58/BR",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102406",
        "Name": "Woodville table lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "OBR79/N",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102431",
        "Name": "Glass Table Lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "HL12021",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102432",
        "Name": "Glass Table Lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "HL12019",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102433",
        "Name": "Glass Table Lamp - red wire",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "HL12028",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102434",
        "Name": "Glass Table Lamp - red wire",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "HL12032",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102478",
        "Name": "Wacko floor lamp",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "BT0785T",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102499",
        "Name": "Lampglob",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "D38",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102501",
        "Name": "2 Lampes Colonnes 1 par",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "Antiquités Lahaye-2693",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102502",
        "Name": "Lampe colonnes á jene",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "AL/2",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102521",
        "Name": "Lampe Devidoir a Laine ink. skärm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "OD033",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102522",
        "Name": "Lampe Miroir ink. skärm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "OD031",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102646",
        "Name": "Dark green base lampe with dots",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "L-B09SEM",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102649",
        "Name": "Lamp base green with purple/",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "L-B09FUCS",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102673",
        "Name": "Lampa inkl skärm",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "Cleo Burtin-3217",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102686",
        "Name": "Chicago floor lamp Black",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "f/ch/5x3B",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102687",
        "Name": "Chicago floor lamp Orange",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "f/ch/5x3O",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102688",
        "Name": "Chicago table lamp black",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "t/ch/2x3B",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102689",
        "Name": "Chicago table lamp Orange",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "t/ch/2x3O",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102690",
        "Name": "Chicago wall lamp Black",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "w/ch/2x3B",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102691",
        "Name": "Chicago wall lamp Orange",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "w/ch/2x3O",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102692",
        "Name": "Double dip hanging lamp Black",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "h/dd/bB",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102693",
        "Name": "Double dip hanging lamp Dark beige",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "h/dd/bDB",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102694",
        "Name": "Double dip hanging lamp Green",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "h/dd/bG",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102695",
        "Name": "Double dip hanging lamp Orange",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "h/dd/bO",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    },
    {
        "ProductId": "102696",
        "Name": "Floor lamp indu small hat",
        "ProductType": { Key: 36, Value: 1 },


        ProductLine: { Key: 1, Value: 1 },
        Manufacturer: { Key: 1, Value: 1 },
        "ManufacturerProductId": "f/indu/1",
        DateValidFrom: new Date(2013 - 10 - 30),
        "DateValidTo": null,
        ProductClass: { Id: 1, Name: ["en-EN", 'Warehouse Items'] },
        ProductSubClass: null,
        Status: { Key: 2, Value: "Active" },




        "IsPhysical": false,
        "IsDigital": null,
        "TaricCode": '',
        CountryOfOrigin: { Key: 1, Value: "India" },
    }
]

const Manufacturers: ManufacturersResponse = {
    Manufacturers:
        [{ Id: 0, Name: "Konsthantverk" },
        { Id: 1, Name: "Vaughan Limited" },
        { Id: 2, Name: "FREJAS BONING" },
        { Id: 3, Name: "Mis en Demeure Decoration" },
        { Id: 4, Name: "Thai Natura" },
        { Id: 5, Name: "VILLA ALYS" },
        { Id: 6, Name: "Tierlantijn BV" },
        { Id: 7, Name: "BRANCO SOBRE BRANCO" },
        { Id: 8, Name: "DÔME DEC" },],
    PageSize: 10,
    Page: 1,
    FirstId: 0,
    LastId: 10,
    Count: 10,
    Remaining: 0,
}





let productTypeResponse: ProductTypeResponse[] =
    [
        {
            Id: 0,
            Name: "root",
            ParentId: null
        },
        {
            Id: 1,
            Name: "Goods",
            ParentId: 0
        },
        {
            Id: 2,
            Name: "Services",
            ParentId: 0
        },
        {
            Id: 3,
            Name: "Utility product type",
            ParentId: 0
        },
        {
            Id: 11,
            Name: "Textiles",
            ParentId: 1
        },
        {
            Id: 19,
            Name: "Accessories",
            ParentId: 1
        },
        {
            Id: 30,
            Name: "The Set Table",
            ParentId: 1
        },
        {
            Id: 31,
            Name: "Lighting",
            ParentId: 1
        },
        {
            Id: 32,
            Name: "Interior Decoration",
            ParentId: 1
        },
        {
            Id: 35,
            Name: "Glasses",
            ParentId: 30
        },
        {
            Id: 36,
            Name: "Lamps",
            ParentId: 31
        },
        {
            Id: 37,
            Name: "Candlesticks",
            ParentId: 32
        },
        {
            Id: 41,
            Name: "Scarves",
            ParentId: 19
        },
        {
            Id: 42,
            Name: "Bags",
            ParentId: 19
        },
        {
            Id: 43,
            Name: "Jewellery",
            ParentId: 19
        },
        {
            Id: 49,
            Name: "Urns",
            ParentId: 32
        },
        {
            Id: 50,
            Name: "Vases",
            ParentId: 32
        },
        {
            Id: 51,
            Name: "Wall decorations",
            ParentId: 32
        },
        {
            Id: 52,
            Name: "Boxes",
            ParentId: 32
        },
        {
            Id: 53,
            Name: "Cushions&Covers",
            ParentId: 11
        },
        {
            Id: 54,
            Name: "Rugs",
            ParentId: 11
        },
        {
            Id: 57,
            Name: "Spreads&Plaids",
            ParentId: 11
        },
        {
            Id: 59,
            Name: "Electrical appliances",
            ParentId: 31
        },
        {
            Id: 60,
            Name: "Lampshades",
            ParentId: 31
        },
        {
            Id: 63,
            Name: "Cutlery",
            ParentId: 30
        },
        {
            Id: 64,
            Name: "Cups",
            ParentId: 30
        },
        {
            Id: 65,
            Name: "Serving accessories",
            ParentId: 30
        },
        {
            Id: 66,
            Name: "Beverage accessories",
            ParentId: 30
        },
        {
            Id: 67,
            Name: "Mirrors",
            ParentId: 32
        },
        {
            Id: 68,
            Name: "Plates",
            ParentId: 30
        },
        {
            Id: 69,
            Name: "Decorative items",
            ParentId: 32
        },
        {
            Id: 70,
            Name: "Frames",
            ParentId: 32
        },
        {
            Id: 71,
            Name: "Trays",
            ParentId: 30
        },
        {
            Id: 72,
            Name: "Jars",
            ParentId: 30
        },
        {
            Id: 73,
            Name: "Furnitures",
            ParentId: 1
        },
        {
            Id: 75,
            Name: "Storage Solutions",
            ParentId: 32
        },
        {
            Id: 76,
            Name: "Pots",
            ParentId: 32
        },
        {
            Id: 77,
            Name: "Baskets",
            ParentId: 32
        },
        {
            Id: 78,
            Name: "Sculptures",
            ParentId: 32
        },
        {
            Id: 80,
            Name: "Seating",
            ParentId: 73
        },
        {
            Id: 81,
            Name: "Tables",
            ParentId: 73
        },
        {
            Id: 82,
            Name: "Candle holders",
            ParentId: 32
        },
        {
            Id: 83,
            Name: "Scented candles",
            ParentId: 32
        },
        {
            Id: 85,
            Name: "Antiques",
            ParentId: 1
        },
        {
            Id: 86,
            Name: "Books",
            ParentId: 1
        },
        {
            Id: 87,
            Name: "Candy",
            ParentId: 1
        },
        {
            Id: 88,
            Name: "Display",
            ParentId: 1
        },
        {
            Id: 90,
            Name: "Body Care",
            ParentId: 1
        },
        {
            Id: 91,
            Name: "Gift card",
            ParentId: 2
        },
        {
            Id: 92,
            Name: "Sofas",
            ParentId: 73
        },
        {
            Id: 93,
            Name: "Other Furnitures",
            ParentId: 73
        },
        {
            Id: 94,
            Name: "Table Textiles",
            ParentId: 30
        },
        {
            Id: 96,
            Name: "Cards",
            ParentId: 32
        },
        {
            Id: 97,
            Name: "Consumable Material",
            ParentId: 1
        },
        {
            Id: 98,
            Name: "Shawl",
            ParentId: 11
        },
        {
            Id: 99,
            Name: "Consultantfee",
            ParentId: 2
        },
        {
            Id: 100,
            Name: "Bowls",
            ParentId: 30
        },
        {
            Id: 101,
            Name: "Fabrics",
            ParentId: 11
        },
        {
            Id: 102,
            Name: "Test Type",
            ParentId: 1
        },
        {
            Id: 105,
            Name: "Barrels",
            ParentId: 75
        },
        {
            Id: 134,
            Name: "Dynor-TEST",
            ParentId: 11
        }
    ]

mock.onGet('/api/products/producttypes').reply(() => {
    return [200, productTypeResponse];
});



mock.onGet('/api/products/producttype').reply((config) => {

    const productTypeId = config.params.params.id;

    const productType = productTypeResponse.find((_productType) => _productType.Id === productTypeId);

    return [200, { productType }];
});


mock.onGet('/api/products/manufacturers').reply(() => {
    return [200, Manufacturers];
});

mock.onGet(RegExp(`/api/products/[0-9]+$`)).reply((config) => {
    const productId = config.url.substring(config.url.lastIndexOf("/") + 1);
    const product = products.find((_product) => _product["ProductId"] === productId);
    return [200, product];
});

mock.onGet(RegExp(`/api/products\?.*$`)).reply(() => {
    return [200, productsResponse];
});