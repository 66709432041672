import { mock } from 'src/utils/axios';

mock.onPut(RegExp(`/api/postput.*$`)).reply((config) => { 
  console.log("Mocked Put with Data:");
  console.log(config);
  return [200,  config ];
});



mock.onPost(RegExp(`/api/postput.*$`)).reply((config) => { 
  console.log("Mocked Post with Data:");
  console.log(config);
  return [200,  config ];
});



