import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';

export interface OneColumnState{
  id: string;
  shown?: boolean;
}
export interface OneTableState{
  columns: OneColumnState[];
  id: string;
}
interface TablesState {
  tables: OneTableState[];
}
const initialState: TablesState = {
  tables: [{columns:[], id:""}]
};

const slice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    toggleTableColumn(state: TablesState,action) {
      if( state.tables.some(table => table.id == action.payload.tableId)){
        // table with id exist
        if(state.tables.find(table => table.id == action.payload.tableId).columns.some(column => column.id == action.payload.columnId)){
          // column in that table exist
          //toggle state
          state.tables.find(table => table.id == action.payload.tableId).columns.find(column => column.id == action.payload.columnId).shown = 
          !state.tables.find(table => table.id == action.payload.tableId).columns.find(column => column.id == action.payload.columnId).shown;
        }
     }
    },
    addTable(state: TablesState,action) {
      if(state.tables.some(table => table.id == action.payload.id)){
        //console.log(`table with ${action.payload.id} id exist`);
      }else{
        
        state.tables.push(action.payload);
      }
    },
    setNewTableColumns(state: TablesState,action) {
      if(state.tables.some(table => table.id == action.payload.id)){
        state.tables.find(table => table.id == action.payload.id).columns = action.payload.columns;        
      }else{
        addTable(action.payload);
      }
    }
  }
});
export const reducer = slice.reducer;

export const addTable = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.addTable(input));
};
export const setNewTableColumns = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.addTable(input));
};
export const toggleTableColumn = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.toggleTableColumn(input));
};
export default slice;
