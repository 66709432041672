import { Box, InputAdornment, TablePagination, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import BulkActions from "./TableBulkActions";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { CustomTablePagination } from "src/components/Atoms/table/TablePagination";

const CreateTableInfoHeader = ({
  hasPageControlOnTop,
  onePageTable,
  serachColumns,
  selectedBulkActions,
  nameQuery,
  sortedDataItems,
  handlePageChange,
  handleQueryChange,
  handleLimitChange,
  page,
  limit,
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <>
      <Box display="flex" alignItems="center">
        {selectedBulkActions && (
          <Box flex={1} p={2}>
            <BulkActions />
          </Box>
        )}
        {!selectedBulkActions && (
          <Box
            flex={1}
            p={0}
            display={{ xs: 'block', md: 'inline' }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              flex={1}
              p={2}
              display={{ xs: 'block', md: 'flex' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                sx={{
                  mb: { xs: 2, md: 0 }
                }}
              >
                {(serachColumns?.length > 0) && (
                  <TextField
                    size="small"
                    onChange={handleQueryChange}
                    value={nameQuery}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchTwoToneIcon />
                        </InputAdornment>
                      )
                    }}
                    placeholder={t('Search...')}
                  />
                )}
              </Box>
              <Box
                sx={{
                  mb: { xs: 2, md: 0 }
                }}
              >
                {((hasPageControlOnTop ?? true)) ?<CustomTablePagination {... {
                                   sortedDataItemsLength:sortedDataItems.length,
                                    limit,
                                    page,
                                    onePageTable,
                                    handlePageChange,
                                    handleLimitChange,
                                }}></CustomTablePagination> : null}
              </Box>
            </Box>


          </Box>
        )}
      </Box>
    </>);
}
export default CreateTableInfoHeader

