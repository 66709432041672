import { Link as RouterLink } from 'react-router-dom';
import { Button, CircularProgress, IconButton, IconButtonPropsColorOverrides } from '@mui/material';
import { ButtonTypeMap } from "@mui/material"
import { FC } from 'react';
import { useDispatch } from 'src/store';
import { showCardById } from 'src/slices/cards';
import { OverridableStringUnion } from '@mui/types';
import { ReactNode } from "react";
import useActionFunctions from 'src/hooks/Buttons/useActionFunctions';

export interface ActionButtonProps {
  title?: string;
  actionUrl?: string;
  isSubmitting?: boolean;
  variant?: ButtonTypeMap["props"]["variant"];
  iconButton?: boolean;
  icon?: ReactNode;
  actionType?: string;
  values?: {};
  color?: OverridableStringUnion<
    'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    IconButtonPropsColorOverrides
  >
  actionFunction?: Function;
}

export const ActionButton = ({
  title,
  actionUrl = null,
  isSubmitting = false,
  variant = 'contained',
  iconButton,
  icon,
  actionType,
  values,
  color = "primary",
  actionFunction = useActionFunctions(actionType, values),
}: ActionButtonProps) => {
  if (iconButton) {
    if (actionUrl) {
      return (
        <IconButton
          component={RouterLink}
          to={actionUrl ?? ''}
          onClick={() => { actionFunction() }}
          color={color}
        >
          {icon}
        </IconButton>
      );
    } else {
      return (
        <IconButton
          onClick={() => { actionFunction() }}
          color={color}
        >
          {icon}
        </IconButton>
      );
    }
  } else {
    if (actionUrl) {
      return (
        <Button
          component={RouterLink}
          to={actionUrl ?? ""}
          variant={variant}
          fullWidth
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : (icon)}
          disabled={isSubmitting}
          sx={{
            mt: { xs: 1, sm: 0 }
          }}
        >
          {title}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => { actionFunction() }}
          variant={variant}
          fullWidth
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : (icon)}
          disabled={isSubmitting}
          sx={{
            mt: { xs: 1, sm: 0 }
          }}
        >
          {title}
        </Button>
      );
    }


  }

}

export default ActionButton;