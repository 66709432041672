import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
import objectArray from 'src/utils/objectArray';
import type { Mail, Tag } from 'src/models/mailbox';

interface  TableHeaderColumnProps {
  headerColumns?:TableHeaderColumnProp[]
 }
interface  TableHeaderColumnProp {
  title:string,
  value?:string,
  selected?:boolean
 
 }

interface TableState {
  headerColumns?:TableHeaderColumnProps,
  sidebarOpen: boolean;
}

const initialState: TableState = {
    headerColumns:null,
    sidebarOpen:false
};



const slice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setFilterHeaders(state: TableState, action: PayloadAction<{ value: string }>) {
        const {value}=action.payload

  
          state.headerColumns.headerColumns.map((headerColumn,i) =>{
            if(headerColumn.value===value){
              state.headerColumns.headerColumns[i].selected=!state.headerColumns.headerColumns[i].selected
            }
          });

      },
    setAllFilterHeaders(state: TableState, action: PayloadAction<{ headerColumns:TableHeaderColumnProps }>) {
        const {headerColumns}=action.payload
        state.headerColumns=headerColumns;
      

    },
  }
});

export const reducer = slice.reducer;

export const setFilterHeaders =
  (value:string): AppThunk =>
   (dispatch) => {

    dispatch(slice.actions.setFilterHeaders({value}));
  };

  export const setAllFilterHeaders =
  (headerColumns:TableHeaderColumnProps): AppThunk =>
   (dispatch) => {
    dispatch(slice.actions.setAllFilterHeaders( {headerColumns}));
  };



export default slice;
