import PageHeader, { PageHeaderProps } from 'src/components/Organisms/PageHeader';

import Footer from 'src/components/Molecules/Footer/Footer';

import SaveIcon from '@mui/icons-material/Save';
import LanguageSwitcher from 'src/components/Molecules/LanguageSwitcher';
import { t } from 'i18next';
import { useContext } from 'react';
import { SettingsContext, SettingsProvider } from 'src/contexts/SettingsContext';

import {
    Divider,
    Box,
    Card,
    Typography,
    alpha,
    Link,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControlLabel, Grid, Switch,
    Table,
    TableContainer,
    IconButton,
    styled,
    useTheme,
    Checkbox
} from '@mui/material';
import Text from 'src/components/Text';
import {
    Sparklines,
    SparklinesLine,
    SparklinesReferenceLine,
    SparklinesSpots
} from 'react-sparklines';
import { useTranslation } from 'react-i18next';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import ArrowDownwardTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import { ContentCardsContainer, CardType, ContentCard } from 'src/components/Organisms/ContentCard';

const pageHeaderData: PageHeaderProps = {
    title: "Webclient Settings",
    subTitle: "These settings only affect this webclient.",
}

export const ToggleDensePadding = () => {
    const { densePadding, togglePadding } = useContext(SettingsContext);
    const handleChange = () => {
        togglePadding();
    }
    return (<FormControlLabel
        label={t('Dense padding in Tables')}
        control={
            <Checkbox
                checked={densePadding}
                onChange={handleChange}
                name="invisible"
                color="primary"
            />
        }
    />);
}

export const ToggleDevelopmentMenu = () => {
    const { developmentMenu, toggleDevelopmentMenu } = useContext(SettingsContext);
    const handleChange = () => {
        toggleDevelopmentMenu();
    }
    return (<FormControlLabel
        label={t('Development Menu')}
        control={
            <Checkbox
                checked={developmentMenu}
                onChange={handleChange}
                name="invisible"
                color="primary"
            />
        }
    />);
}

export const ToggleMockApi= () => {
    const { mockApi, toggleMockApi } = useContext(SettingsContext);
    const handleChange = () => {
        toggleMockApi();
    }
    return (<FormControlLabel
        label={t('Mock')}
        control={
            <Checkbox
                checked={mockApi}
                onChange={handleChange}
                name="invisible"
                color="primary"
            />
        }
    />);
}



function WebclientSettings() {
    return (
        <>
            <PageHeader  {...pageHeaderData} />
            <ContentCardsContainer >
                <ContentCard {...{
                    cardId: "General Settings",
                    cardSubTitle: "These settings affect the webclient but does not affect anything else",
                    cardTypes: [CardType.collapsable, CardType.visibleInitally]

                }}>
                    <label>Select Language: </label><LanguageSwitcher />
                </ContentCard>
                <ContentCard {...{
                    cardId: "Table Settings",
                    cardSubTitle: "These settings change the visual and functionality of all Tables in the webclient",
                    cardTypes: [CardType.collapsable, CardType.visibleInitally]
                }}>
                    <ToggleDensePadding></ToggleDensePadding>
                    
                </ContentCard>
                <ContentCard {...{
                    cardId: "Developer Settings",
                    cardSubTitle: "These settings are used for Developers to change/show features",
                    cardTypes: [CardType.collapsable, CardType.visibleInitally ]

                }}>
                    
                    <ToggleDevelopmentMenu></ToggleDevelopmentMenu><br></br>
                    <ToggleMockApi></ToggleMockApi>
                </ContentCard>
            </ContentCardsContainer>
            <Footer />
        </>
    );
}

export default WebclientSettings;
