import { useDispatch, useSelector } from 'src/store';
import { addTable } from 'src/slices/tables';
import { useEffect } from 'react';


const useHeaderColumns = (tableId, tableColumns?) => {
  const dispatch = useDispatch();
  const dispatchTable = () => {
    dispatch(addTable({ id: tableId, columns: tableColumns }));
  }
  useEffect(() => {
      dispatchTable();
  }, [dispatchTable]);
  
  const { tables } = useSelector(
    (state) => state.tables
  );
  const headerColumns = tables?.find(table => table.id == tableId)?.columns;
  return headerColumns;
};

export default useHeaderColumns;