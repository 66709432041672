import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { FC } from "react";
import { OneColumnState, toggleTableColumn } from "src/slices/tables";
import { useDispatch } from "src/store";

const TableColumnSettings: FC<{ tableId: string, columns: OneColumnState[] }> = ({ tableId, columns }) => {
    const dispatch = useDispatch();
    return (
        <>
            <Grid container p={2} spacing={4} >
                {columns?.map((column, i) => (
                    (<Grid item key={i} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => { dispatch(toggleTableColumn({ tableId: tableId, columnId: column.id })) }}
                                    checked={column.shown}
                                />
                            }
                            label={column.id} />
                    </Grid>)
                ))}
            </Grid>
        </>
    );
}
export default TableColumnSettings;
