import PageHeader, { PageHeaderProps } from 'src/components/Organisms/PageHeader';

import Footer from 'src/components/Molecules/Footer/Footer';
import FullTable from 'src/components/Organisms/FullTable';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ActionButton from 'src/components/Atoms/Buttons/ActionButton';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/Organisms/ContentCard';
import TableColumnSettings from 'src/components/Molecules/Table/TableColumnSettings';
import { FullTableProps } from 'src/components/Organisms/FullTable';
import useSalesOrdersTable from 'src/hooks/Table/useSalesOrdersTable';


function Orders() {
    const [salesOrders, tableSettings, headerColumns] = useSalesOrdersTable();
    return (
        <>
            <PageHeader  {...{
                title: "Sales Orders",
                subTitle: "Orders from both websites and in store purchases",

                components: [
                    { component: <ActionButton title="New Return Order" icon={<AddTwoToneIcon />} actionUrl="/management/commerce/products/create" /> },
                    { isInGroupedMenu: true, component: <ActionButton title="Edit Columns" actionType="showHiddenCard" values="OrderColumsEditor" variant='text' /> },
                ],
            }} />
            <ContentCardsContainer >
                <ContentCard {...{
                    cardId: "OrderColumsEditor",
                    cardTitleText: "Show and hide columns",
                    cardTypes: [CardType.hideable]
                }}>
                    <TableColumnSettings columns={headerColumns} tableId={tableSettings.tableId}></TableColumnSettings>
                </ContentCard>
                <ContentCard {...{
                    cardId: "Orders",
                    cardTypes: [CardType.noHeader],
                    contentPadding: 0
                }}>
                    <FullTable tableData={salesOrders}{...tableSettings} headerColumns={headerColumns} />
                </ContentCard>
            </ContentCardsContainer>

            <Footer />
        </>
    );
}

export default Orders;
