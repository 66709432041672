import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';


export interface OneCardState{
  isCardHidden?: boolean;
  isCardVisible?: boolean;
  id: string;
}

interface CardState {
  isCardHidden: boolean;
  cards: OneCardState[];
}
interface CardsState {
  cardsStates: CardState[];
}
const initialState: CardState = {
  isCardHidden: false,
  cards: []
};


const slice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    showCard(state: CardState) {
      state.isCardHidden = false;
    },
    hideCard(state: CardState) {
      state.isCardHidden = true;
    },
    showCardById(state: CardState,action) {
      state.cards.find(card => card.id == action.payload).isCardVisible = true;
    },
    hideCardById(state: CardState, action) {
      state.cards.find(card => card.id == action.payload).isCardVisible = false;
    },
    addCardState(state: CardState, action){
      if(state.cards.some(card => card.id == action.payload.id)){
        //console.log("id already in list");
      }else{
        state.cards.push(action.payload);
        
      }
      
    },
    toggleCardHiddenById(state: CardState, action){
       state.cards.find(card => card.id == action.payload).isCardHidden = !state.cards.find(card => card.id == action.payload).isCardHidden;
    },
  }
});

export const reducer = slice.reducer;


export const showCard = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.showCard());
};

export const addCardState = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.addCardState(input));
};

export const showCardById = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.showCardById(input));
};
export const hideCardById = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.hideCardById(input));
};
export const toggleCardHiddenById = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.toggleCardHiddenById(input));
};

export const hideCard = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.hideCard());
};

export default slice;
