import React, { useState, useEffect, createContext, FC } from 'react';

interface ISettingsContext {
  densePadding: boolean;
  togglePadding?: () => void;
  developmentMenu: boolean;
  toggleDevelopmentMenu?: () => void;
  mockApi: boolean;
  toggleMockApi?: () => void;
}
const defaultState = {
  densePadding: false,
  developmentMenu: true,
  mockApi: false,
};

export const SettingsContext = createContext<ISettingsContext>(defaultState);
export const SettingsProvider: FC = ({ children }) => {
  const [densePadding, setDensePadding] = useState(defaultState.densePadding);
  const togglePadding = () => {
    setDensePadding(!densePadding);
  };
  const [developmentMenu, setDevelopmentMenu] = useState(defaultState.developmentMenu);
  const toggleDevelopmentMenu = () => {
    setDevelopmentMenu(!developmentMenu);
  };
  const [mockApi, setMockApiStatus] = useState(defaultState.mockApi);
  const toggleMockApi = () => {
    setMockApiStatus(!mockApi);
    localStorage.setItem("mockApi", (!mockApi).toString())
  };
  useEffect(() => {
    var storedMockApiState = (localStorage.getItem("mockApi") === 'true');
    setMockApiStatus(storedMockApiState);
}, [setMockApiStatus]);
  return (
    <SettingsContext.Provider
      value={{
        densePadding,
        togglePadding,
        developmentMenu,
        toggleDevelopmentMenu,
        mockApi,
        toggleMockApi,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
