import { useState, useEffect, useCallback, FC } from 'react';
import axios from 'src/utils/axios';
import PageHeader, { PageHeaderProps } from 'src/components/Organisms/PageHeader';

import Footer from 'src/components/Molecules/Footer/Footer';
import useRefMounted from 'src/hooks/useRefMounted';
import { SalesItem } from 'src/models/sale_item';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ActionButton from 'src/components/Atoms/Buttons/ActionButton';
import FullTable from 'src/components/Organisms/FullTable';
import { CreateHeaderColumns } from 'src/components/Organisms/FullTable';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/Organisms/ContentCard';
import { useDispatch, useSelector } from 'src/store';
import { addTable, toggleTableColumn, OneColumnState } from 'src/slices/tables';
import TableColumnSettings from 'src/components/Molecules/Table/TableColumnSettings';
import { FullTableProps } from 'src/components/Organisms/FullTable';
import { FlattenProductTypes, FullTree, MapProductTypesToTreeItems, MapTreeItemsToState, NestedTreeItemProps, TreeSettingProps } from 'src/components/Organisms/FullTree';
import { ProductAttribute, ProductType } from 'src/models/product_type';
import { addTree, OneTreeState } from 'src/slices/trees';
import { Checkbox } from '@mui/material';
import axiosInt from 'src/utils/axios';

const pageHeaderData: PageHeaderProps = {
    title: "Product Types ",
    subTitle: "View and edit of Product Types",
}

const ProductTypeAttributes: FC<{ productType: ProductType, tableSettings: FullTableProps }> = ({ productType, tableSettings }) => {
    let attibuteTableData = [];
    productType.attributes?.forEach((attribute) => {
        let currentAttribute = {name:"", enabled:null, required: null};
        currentAttribute.name = attribute.name;
        currentAttribute.enabled = <Checkbox defaultChecked={attribute.enabled} />
        currentAttribute.required = <Checkbox defaultChecked={attribute.required}/>
        attibuteTableData.push(currentAttribute);
      });

    return (
        <>
            <div>
                {productType.attributes?.length > 0 ? <FullTable tableData={attibuteTableData ?? []}{...tableSettings} /> : null}
            </div>
        </>);
}

function getAllAttributes(): ProductAttribute[] {
    return [
        { "name": "height", "enabled": false, "required": false },
    ]
}

const ProductTypesDetails: FC<{ productTypes?: ProductType[], treeState?: OneTreeState, }> = ({ productTypes, treeState }) => {
    const tableSettings: FullTableProps = {
        tableId: "productAttributes",
        id_column: "name",
        hasDeleteButton: false,
        hasDoubleScroll: false,
        hasBulkActionCheckboxes: false,
        hasPageControlOnTop: false,
        hasTableInfo: false,
        onePageTable: true,
    }
    const dispatch = useDispatch();
    const allAttributes = getAllAttributes();
    const columns: OneColumnState[] = CreateHeaderColumns(allAttributes);
    useEffect(() => {
        dispatch(addTable({ id: tableSettings.tableId, columns: columns }));
    }, []);
    const selectedProductTypes = productTypes.filter(productType => (treeState?.treeItemStates?.some(treeState => (treeState.id == productType.id && treeState.selected))));
    return (
        <>
            <ContentCard {...{
                cardId: "Procut Types Details",
                cardTitleText: "Procut Types Details - " + (selectedProductTypes[0]?.name ?? ""),
                md: 6.5
            }}>
                {selectedProductTypes?.map((productType, i) =>
                (<div key={i}>
                    <ProductTypeAttributes productType={productType} tableSettings={tableSettings} ></ProductTypeAttributes>
                </div>))}
            </ContentCard>
        </>);
}
const treeSettings: TreeSettingProps = {
    hasCheckboxes: true,
    id: "ProductTypesTree"
}

function ProductTypes() {
    const isMountedRef = useRefMounted();
    const dispatch = useDispatch();
    const [productTypes, setProductTypes] = useState<ProductType[]>([]);
    const [flatProductTypes, setFlatProductTypes] = useState<ProductType[]>([]);
    const [treeItems, setTreeItems] = useState<NestedTreeItemProps[]>([]);
    const getProductTypes = useCallback(async () => {
            const response = await axiosInt.get<{ productTypes: ProductType[] }>(
                '/api/product-types'
            );
            if (isMountedRef()) {
                setProductTypes(response.data.productTypes);
                const mappedTreeItemsResponse = MapProductTypesToTreeItems(response.data.productTypes);
                setTreeItems(mappedTreeItemsResponse);
                setFlatProductTypes(FlattenProductTypes(response.data.productTypes));
                const treeItemStates = MapTreeItemsToState(mappedTreeItemsResponse);
                dispatch(addTree({ id: treeSettings.id, treeItemStates: treeItemStates }))
            }
    }, [isMountedRef()]);
    useEffect(() => {
        getProductTypes();
    }, [getProductTypes]);
    let treeState: OneTreeState;
    const { trees } = useSelector(
        (state) => state.trees
    );
    treeState = trees?.find(tree => tree.id == treeSettings.id) ?? { id: "", treeItemStates: null };
    return (
        <>
            <PageHeader  {...pageHeaderData} />
            <ContentCardsContainer >
                <ContentCard {...{ cardId: "Product Types", md: 5.5 }}>
                    <FullTree treeItems={treeItems} treeSettings={treeSettings}></FullTree>
                </ContentCard>
                <ProductTypesDetails treeState={treeState} productTypes={flatProductTypes} ></ProductTypesDetails>
            </ContentCardsContainer>
            <Footer />
        </>
    );
}

export default ProductTypes;

