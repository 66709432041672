import { useState, useEffect, useCallback, FC } from 'react';
import PageHeader, { PageHeaderProps } from 'src/components/Organisms/PageHeader';

import Footer from 'src/components/Molecules/Footer/Footer';
import FullTable from 'src/components/Organisms/FullTable';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ActionButton from 'src/components/Atoms/Buttons/ActionButton';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/Organisms/ContentCard';
import TableColumnSettings from 'src/components/Molecules/Table/TableColumnSettings';
import useSalesOrdersTable from 'src/hooks/Table/useSalesOrdersTable';


const pageHeaderData: PageHeaderProps = {
    title: "Returns",
    subTitle: "Sales Orders with stats Return",
    components: [
        {component:<ActionButton title="New Return Order" icon={<AddTwoToneIcon />} actionUrl="/management/commerce/products/create" />},
        {isInGroupedMenu:true, component:<ActionButton title="Edit Columns"  actionType="showHiddenCard" values="OrderColumsEditor" variant='text'/>},
    ],

}




function Orders() {
    const [salesOrders, tableSettings, headerColumns] = useSalesOrdersTable();

    return (
        <>
            <PageHeader  {...pageHeaderData} />
            <ContentCardsContainer >
                <ContentCard {...{
                    cardId: "OrderColumsEditor",
                    cardTitleText: "Show and hide columns",
                   cardTypes: [CardType.hideable]
                }}>
                    <TableColumnSettings columns={headerColumns} tableId={tableSettings.tableId}></TableColumnSettings>
                </ContentCard>
                <ContentCard {...{
                    cardId: "Orders",
                    cardTypes: [CardType.noHeader],
                    contentPadding: 0
                }}>
                    <FullTable tableData={salesOrders}{...tableSettings} />
                </ContentCard>
            </ContentCardsContainer>

            <Footer />
        </>
    );
}

export default Orders;
