import { Suspense, lazy } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import SuspenseLoader from 'src/components/Molecules/SuspenseLoader';
import Orders from 'src/pages/Orders/Orders/Orders';
import Returns from 'src/pages/Orders/Returns/Returns';
import Categories from 'src/pages/Products/Categories/Categories';
import ImagesResources from 'src/pages/Products/Images and Resources/Images and Resources';
import Manufacturers from 'src/pages/Products/Manufacturers/Manfufacturers';
import ProductTypes from 'src/pages/Products/Product Types/Product Types';
import SupplierProducts from 'src/pages/Products/Supplier Products/Supplier Products';
import LowOrderPoints from 'src/pages/Purchasing/Low Order Points/Low Order Points';
import PurchaseOrderDraft from 'src/pages/Purchasing/Purchase Order Draft/Purchase Order Draft';
import PurchaseOrder from 'src/pages/Purchasing/Purchase Order/Purchase Order';
import Suppliers from 'src/pages/Purchasing/Suppliers/Suppliers';
import CustomerReturnOrders from 'src/pages/Reports/Customer Return Orders/Customer Return Orders';
import ProductRecieveReport from 'src/pages/Reports/Product Recieve Report/Product Recieve Report';
import CampaignOrderReport from 'src/pages/Reports/Campaign Reports/Campaign Order Report/Campaign Order Report';
import CampaignSalesItemsSales from 'src/pages/Reports/Campaign Reports/Sales Items Sales/Sales Items Sales';
import SalesItemsSales from 'src/pages/Reports/Sales Reports/Sales Items Sales/Sales Items Sales';
import SalesOrderReport from 'src/pages/Reports/Sales Reports/Sales Order Report/Sales Order Report';
import ProductsHomePage from 'src/pages/Products/ProductsPagesOverview';
import OrdersHomePage from 'src/pages/Orders/OrderPagesOverview';
import PurchasingHomePage from 'src/pages/Purchasing/Purchasing';
import ReportsHomePage from 'src/pages/Reports/Reports';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Product
const Products = Loader(lazy(() => import('src/pages/Products/Products/Products')));
const ProductsCreate = Loader(lazy(() => import('src/pages/Products/Products/EditProduct')));
const SalesItems = Loader(lazy(() => import('src/pages/Products/Sales Items/SalesItems')));
const SalesItemsCreate = Loader(lazy(() => import('src/pages/Products/Sales Items/EditSalesItem')));

//Account 
const Register = Loader(lazy(() => import('src/pages/Auth/Register/Register')));

// Settings
const WebclientSettings = Loader(lazy(() => import('src/pages/Settings/WebclientSettings')));
const IntegrationSettings = Loader(lazy(() => import('src/pages/Settings/IntegrationSettings')));

// Testing
  const SalesItemsTesting = Loader(lazy(() => import('src/pages/Testing/Sales Items/SalesItemsBasic')));
  const TableTesting = Loader(lazy(() => import('src/pages/Templates/Tables/SalesItemTableTesting')));
  const OrderTableTesting = Loader(lazy(() => import('src/pages/Templates/Tables/OrderTableTemplate')));
  const CreateProductFormTemplate = Loader(lazy(() => import('src/pages/Templates/Forms/CreateProductForm')));
  const TemplateForm = Loader(lazy(() => import('src/pages/Templates/Forms/TemplateForm')));
  const TreeTemplateProductTypes = Loader(lazy(() => import('src/pages/Templates/Trees/TreeTemplateProductTypes')));
  const TreeTemplateCategories = Loader(lazy(() => import('src/pages/Templates/Trees/TreeTemplateCategories')));
  const KalleTesting = Loader(lazy(() => import('src/pages/Testing/Kalle/Kalle')));
  const KalleTesting2 = Loader(lazy(() => import('src/pages/Testing/Kalle/Kalle2')));
  const CardTesting = Loader(lazy(() => import('src/pages/Templates/Card/CardTesting')));
  const ErikTesting = Loader(lazy(() => import('src/pages/Testing/Erik/Erik')));
  const OlleTesting = Loader(lazy(() => import('src/pages/Testing/Olle/Olle')));
  const ApiTesting = Loader(lazy(() => import('src/pages/Testing/Api/Api')));
  const Api2Testing = Loader(lazy(() => import('src/pages/Testing/Api/Api2')));
  const Api3Testing = Loader(lazy(() => import('src/pages/Testing/Api/Api3')));
  

const pagesRoutes = [

  {
    path: 'products',
    children: [
      {
        path: '',
        element: <ProductsHomePage />
      },
      {
        path: 'products',
        element: <Products />
      },
      
      {
        path: 'products',
        children: [
          {
            path: 'create',
            element: <ProductsCreate />
          },
          {
            path: 'edit',
            element: <ProductsCreate />
          },
    
    
        ]
      },
      {
        path: 'product-types',
        element: <ProductTypes />
      },
      
      {
        path: 'sales-items',
        element: <SalesItems />
      },
      {
        path: 'sales-items',
        children: [
          {
            path: "create/",
            element: <SalesItemsCreate />
          },
          {
            path: "edit/",
            element: <SalesItemsCreate />
          },

        
    
    
        ]
      },
      {
        path: "sales-items/:categories",
        element: <SalesItems />
      },
      {
        path: 'categories',
        element: <Categories />
      },
      {
        path: 'supplier-products',
        element: <SupplierProducts />
      },
      {
        path: 'manufacturers',
        element: <Manufacturers />
      },
      {
        path: 'resources',
        element: <ImagesResources />
      },
      
    ]
    
    
  },
  {
    path: 'reports',
    children: [
      {
        path: '',
        element: <ReportsHomePage />
      },
      {
        path: 'campaign-reports',
        children: [
          {
            path: 'sales-order-report',
            element: <CampaignOrderReport />
          },
          {
            path: 'sales-items-report',
            element: <CampaignSalesItemsSales />
          },
        ]
      },
      {
        path: 'sales-reports',
        children: [
          {
            path: 'sales-order-reports',
            element: <SalesOrderReport />
          },
          {
            path: 'sales-items-sales',
            element: <SalesItemsSales />
          },
        ]
      },
     
      {
        path: 'product-recieve-report',
        element: <ProductRecieveReport />
      },
      {
        path: 'customer-return-order-report',
        element: <CustomerReturnOrders />
      },


    ]
  },
  {
    path: 'settings',
    children: [
      {
        path: '',
        element: <Navigate to="webclient" replace />
      },
      {
        path: 'webclient',
        element: <WebclientSettings />
      },
      {
        path: 'integrations',
        element: <IntegrationSettings />
      },


    ]
  },
  {
    path: 'accounts',
    children: [
      {
        path: 'register',
        element: (
            <Register />
        )
      }, 


    ]
  },
  
  {
    path: 'testing',
    children: [
      {
        path: '',
        element: <Navigate to="sales-items" replace />
      },

      
      {
        path: 'kalle',
        element: <KalleTesting />
      },
      {
        path: 'kalle2',
        element: <KalleTesting2 />
      },
      {
        path: 'erik',
        element: <ErikTesting />
      },
      {
        path: 'olle',
        element: <OlleTesting />
      },
      {
        path: 'api',
        element: <ApiTesting />
      },
      {
        path: 'api2',
        element: <Api2Testing />
      },
      {
        path: 'api3',
        element: <Api3Testing />
      },
    ]
  },
  {
    path: 'templates',
    children: [
      {
        path: 'cards',
        element: <CardTesting />
      },
      {
        path: 'create-product-form',
        element: <CreateProductFormTemplate />
      },
      {
        path: 'template-form',
        element: <TemplateForm />
      },
      {
        path: 'sales-item-table',
        element: <TableTesting />
      },
      {
        path: 'sales-order-table',
        element: <OrderTableTesting />
      },
      {
        path: 'product-types-tree',
        element: <TreeTemplateProductTypes />
      },
      {
        path: 'categories-tree',
        element: <TreeTemplateCategories />
      },
      
    ]
  },
  {
    path: 'orders',
    children: [
      {
        path: '',
        element: <OrdersHomePage />
      },
      {
        path: 'orders',
        element: <Orders />
      },
      {
        path: 'returns',
        element: <Returns />
      },
      ]
  },
  {
    path: 'purchasing',
    children: [
      {
        path: '',
        element: <PurchasingHomePage />
        
        
      },
      {
        path: 'purchase-order',
        element: <PurchaseOrder />
      },
      {
        path: 'purchase-order-draft',
        element: <PurchaseOrderDraft />
      },
      {
        path: 'low-order-points',
        element: <LowOrderPoints />
      },
      {
        path: 'suppliers',
        element: <Suppliers />
      },
      ]
  }
];

export default pagesRoutes;
