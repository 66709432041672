import { Order } from "src/enums/general";

const applyFilters = (serachColumns, nameQuery: string, tableData?: any[]): any[] => {
    return tableData?.filter((tableItem) => {
        let matches = true;
        if (nameQuery) {
            
            let containsQuery = false;
            serachColumns.forEach((property) => {
                if (tableItem[property].toLowerCase().includes(nameQuery.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (!containsQuery) {
                matches = false;
            }
        }

        return matches;
    });
};
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}



const applySort = (tableData: any[], order: Order, orderBy: string): any[] => {
    const sortedTableData = tableData?.sort(getComparator(order, orderBy))
    return sortedTableData;
};
const applyPagination = (
    tableData: any[],
    page: number,
    limit: number
): any[] => {
    return tableData?.slice(page * limit, page * limit + limit);
};
const applySorting = ( serachColumns, order: Order, orderBy: string, nameQuery: string, tableData?: any[]): any[] => {
    const filteredTableData = applyFilters(serachColumns, nameQuery, tableData);
    const sortedTableData = applySort(filteredTableData, order, orderBy);
    return sortedTableData;
};


const TableHelper = {

    applyPagination,
    applySorting
};

export default TableHelper;
