import { Alert, AlertProps, Link, } from '@mui/material';
import { useEffect, useState } from 'react';
import ActionButton from './Buttons/ActionButton';
import CloseIcon from '@mui/icons-material/Close';

export interface CustomAlertProps extends AlertProps {
    dataArray?: any[],
    hidden?: boolean
}

export const CustomAlert = ({
    hidden = false,
    severity = 'success',
    dataArray,
    ...props
}: CustomAlertProps) => {
    const [alertHidden, setAlertHidden] = useState<boolean>(hidden);
    useEffect(() => {
        setAlertHidden(false)
      }, [dataArray]);
    if (!alertHidden && (!(props.children == undefined) || !(dataArray == undefined))) {
        const dataAsString = JSON.stringify(dataArray, undefined, 2);
        const prettyData = dataAsString.replace(/\n/g, "<br>").replace(/[ ]/g, "&nbsp;").replace(/\,/g, ",<br>") .replace(/\\"/g, '"');
        return (<> <Alert action={
            <ActionButton iconButton={true} color={severity}  icon={<CloseIcon></CloseIcon>} actionFunction={() => setAlertHidden(true)}></ActionButton>
        }
            style={{ paddingBottom: 2 }} severity={severity}>
            {props.children}
            <pre   dangerouslySetInnerHTML={{__html: prettyData}} ></pre>
        </Alert></>);
    }
    return <></>

}
