import { Checkbox,  IconButton, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ConfirmPopUp from "src/components/Organisms/ConfirmPopUp";
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink,  } from 'react-router-dom';
import {  useDispatch, } from 'src/store';
import {  FC, useContext } from "react";
import { SettingsContext } from "src/contexts/SettingsContext";
import {  SalesItemStatus } from "src/models/sale_item";
import Label from 'src/components/Atoms/Label';
const getInvoiceStatusLabel = (salesItemStatus: SalesItemStatus): JSX.Element => {
  const map = {
    Active: {
      text: 'Active',
      color: 'success'
    },
    Inactive: {
      text: 'Inactive',
      color: 'info'
    },
    Discontinued: {
      text: 'Discontinued',
      color: 'warning'
    },
    empty: {
      text: '',
      color: 'primary'
    }
  };
  const { text, color }: any = map[salesItemStatus];
  return (
    <Label color={color}>
      <b> {text}</b>
    </Label>
  );
};


const CreateTableRow = ({ 
  headerColumns, 
  rowData, 
  id_column,
  editLink,
  hasBulkActionCheckboxes,
  handleSelectOneItem, 
  hasDeleteButton,
  isItemSelected,
  index
 }) => {
  
  const { densePadding, togglePadding } = useContext(SettingsContext);
  const { t }: { t: any } = useTranslation();
  return (
    <>
      <TableRow
        hover
        key={index}
        selected={isItemSelected}
      >
          {(hasBulkActionCheckboxes ?? true) ? <TableCell padding={densePadding ? 'none' : 'checkbox'}>
        <Checkbox
            checked={isItemSelected}
            onChange={(event) =>
              handleSelectOneItem(event, rowData[id_column])
            }
            value={isItemSelected}
          />
          </TableCell>  : null }
          <TableCell align="center" padding={densePadding ? 'none' : 'normal'}>
          <Typography noWrap>
            {(editLink ?? false) ?  
            <Tooltip title={t('View')} arrow>
            <IconButton
              component={RouterLink}
              to={editLink+rowData[id_column]}
              color="primary"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
           : null }
            {(hasDeleteButton ?? true) ?  <ConfirmPopUp></ConfirmPopUp> : null }
          </Typography>
        </TableCell>
        {headerColumns.map((column, i) => (
          column.shown ? (
            <TableCell key={i} align="center" padding={densePadding ? 'none' : 'normal'}>{
              (typeof  rowData[column.id] == 'boolean') ? (rowData[column.id] ? "Yes" : "No") : rowData[column.id] 
            }</TableCell>
          ) : null
        ))}
      </TableRow>
    </>
  );
}

function CreateTableBody({
  id_column,
  headerColumns,
  editLink,
  hasBulkActionCheckboxes,
  hasDeleteButton,
  tableData,
  selectedItems,
  handleSelectOneItem,
}) {
  const { t }: { t: any } = useTranslation();
  return (
    <TableBody>
      {tableData?.map((rowData, index) => {
      
        const isItemSelected = selectedItems.includes(
          rowData[id_column]
        );
        return <CreateTableRow key={index}  {...{
          headerColumns: headerColumns,
          rowData: rowData,
          id_column: id_column,
          editLink: editLink,
          hasBulkActionCheckboxes :hasBulkActionCheckboxes,
          handleSelectOneItem:handleSelectOneItem, 
          hasDeleteButton: hasDeleteButton,
          isItemSelected:isItemSelected,
          index:index

        }}></CreateTableRow>
        
      })}
    </TableBody>
  );
} export default CreateTableBody;