import { useState, ChangeEvent, useContext, useEffect } from 'react';
import { Box, Table, TablePagination, TableContainer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CreateTableBody from 'src/components/Molecules/Table/TableBody';
import CreateTableHead from 'src/components/Molecules/Table/TableHead';
import CreateTableInfoHeader from '../Molecules/Table/TableControls';
import DoubleScrollbar from 'react-double-scrollbar';
import { SettingsContext } from 'src/contexts/SettingsContext';
import th from 'src/utils/tables/tableHelper';
import { useTableOrderByState, useTablePageState, useTableSearchState, useTableSelectItemsStates } from 'src/hooks/Table/useTableStates';
import { CustomTablePagination } from '../Atoms/table/TablePagination';



export interface FullTableProps {
    tableId: string,
    id_column: string,
    headerColumns?: any[];
    serachColumns?: string[];
    preDefinedSearchTerm?: string;
    editLink?: string;
    hasDeleteButton?: boolean;
    hasDoubleScroll?: boolean;
    hasBulkActionCheckboxes?: boolean;
    hasPageControlOnTop?: boolean;
    hasTableInfo?: boolean;
    onePageTable?: boolean;
    tableData?: any[];
}

const FullTable = ({
    tableId,
    id_column,
    serachColumns,
    headerColumns = [],
    preDefinedSearchTerm,
    editLink,
    hasDeleteButton,
    hasDoubleScroll,
    hasBulkActionCheckboxes,
    hasPageControlOnTop,
    hasTableInfo,
    onePageTable,
    tableData = [],
}: FullTableProps) => {
    const [selectedItems, handleSelectAllItems, handleSelectOneItem] = useTableSelectItemsStates(id_column, tableData);
    const { densePadding } = useContext(SettingsContext);
    let limits = densePadding ? 20 : 10
    limits = onePageTable ? tableData?.length : limits;
    const [page, limit, handlePageChange, handleLimitChange] = useTablePageState(limits, onePageTable, tableData?.length ?? 0);
    const [nameQuery, handleQueryChange] = useTableSearchState();
    const [order, orderBy, handleRequestSort] = useTableOrderByState(id_column);
    const sortedDataItems = th.applySorting(serachColumns, order, orderBy, nameQuery, tableData ?? [])
    const paginatedDataItems = th.applyPagination(sortedDataItems, page, limit);
    const selectedBulkActions = selectedItems.length > 0;
    const selectedSomeItems = selectedItems.length > 0 && selectedItems.length < tableData?.length;
    const selectedAllItems = selectedItems.length === tableData?.length;
    return (
        <>
            {(hasTableInfo ?? true) ?
                <CreateTableInfoHeader {...{
                    hasPageControlOnTop,
                    onePageTable,
                    serachColumns,
                    selectedBulkActions,
                    nameQuery,
                    sortedDataItems,
                    handlePageChange,
                    handleQueryChange,
                    handleLimitChange,
                    page,
                    limit,
                }}></CreateTableInfoHeader> : null}
            {sortedDataItems.length === 0 ? (
                <NoResultInfo />
            ) : (
                <>
                    <TableContainer  >
                        <ConditionalDoublescroll showDoubleScroll={hasDoubleScroll ?? true}>
                            <Table sx={{
                                width: "max-content",
                                minWidth: "100%",
                            }}>
                                <CreateTableHead {... {
                                    headerColumns,
                                    handleSelectAllItems,
                                    selectedAllItems,
                                    selectedSomeItems,
                                    hasBulkActionCheckboxes,
                                    order,
                                    orderBy,
                                    handleRequestSort,
                                    densePadding,
                                }}></CreateTableHead>
                                <CreateTableBody  {...{
                                    id_column,
                                    headerColumns,
                                    editLink,
                                    hasBulkActionCheckboxes,
                                    hasDeleteButton,
                                    tableData: paginatedDataItems,
                                    selectedItems,
                                    handleSelectOneItem
                                }}></CreateTableBody>
                            </Table>
                        </ConditionalDoublescroll>
                    </TableContainer>
                   <CustomTablePagination {... {
                                   sortedDataItemsLength:sortedDataItems.length,
                                    limit,
                                    page,
                                    onePageTable,
                                    handlePageChange,
                                    handleLimitChange,
                                }}></CustomTablePagination>
                </>
            )}
        </>
    );
};


const NoResultInfo = () => {
    const { t }: { t: any } = useTranslation();
    return (<Typography
        sx={{
            py: 10
        }}
        variant="h3"
        fontWeight="normal"
        color="text.secondary"
        align="center"
    >
        {t("We couldn't find anything matching your search criteria")}
    </Typography>);
}

export const CreateHeaderColumns = (tableData: any[]) => {
    let headerColumns = [];
    if (tableData?.length > 0) {
        Object.keys(tableData[0]).map(key => {
            headerColumns.push({ id: key, shown: true })
        })
    }
    return headerColumns;
};

const ConditionalDoublescroll = ({ showDoubleScroll, children }: { showDoubleScroll: boolean, children }) => {
    if (showDoubleScroll) { return (<DoubleScrollbar>{children}</DoubleScrollbar>) } else return (<>{children}</>);
}
export default FullTable;