import { useDispatch, useSelector } from 'src/store';
import { CreateHeaderColumns } from 'src/components/Organisms/FullTable';
import { addTable } from 'src/slices/tables';
import { useEffect } from 'react';
import {  showCardById } from 'src/slices/cards';


const useActionFunctions = (actionType, values) => {
  const dispatch = useDispatch();
  let actionFunction  = () => {}
  if (actionType == "showHiddenCard") {
    actionFunction = () => { dispatch(showCardById(values)); };
  }
  return actionFunction
};

export default useActionFunctions;
