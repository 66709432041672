import { Suspense } from "react";
import SuspenseLoader from "src/components/Molecules/SuspenseLoader";

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

export default Loader;