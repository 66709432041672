const seJSON = {
    Blueprints: 'Plans',
    'Extended Sidebar': 'Barre latérale étendue',
    'Accent Sidebar': "Barre latérale d'accentuation",
    'Accent Header': 'En-tête accentué',
    'Boxed Sidebar': 'Barre latérale en boîte',
    'Collapsed Sidebar': 'Barre latérale réduite',
    'Bottom Navigation': 'Navigation du bas',
    'Top Navigation': 'Navigation supérieure',
    'Lead Developer': 'Développeur principal',
    'Mega menu': 'Méga menu',
    Doctors: 'Médecins',
    Reports: 'Rapports',
    'Custom dashboard built using the included components':
      "Tableau de bord personnalisé construit à l'aide des composants inclus",
    Hospital: 'Hôpital',
    Export: 'Exportation',
    'Last year': "L'année dernière",
    Expenses: 'Dépenses',
    Products: 'Des produits',
    Statistics: 'Statistiques',
    General: 'Général',
    Automation: 'Automatisation',
    'Data Display': 'Affichage des données',
    Calendar: 'Calendrier',
    Mailbox: 'Boites aux lettres',
    Dashboards: 'Tableaux de bord',
    Analytics: 'Analytique',
    Banking: 'Bancaire',
    Commerce: 'Commerce',
    Crypto: 'Crypto',
    Finance: 'La finance',
    Fitness: 'Aptitude',
    Healthcare: 'Soins de santé',
    'Doctors Page': 'Page des médecins',
    'Hospital Overview': "Aperçu de l'hôpital",
    Helpdesk: "Bureau d'aide",
    Learning: 'Apprentissage',
    Monitoring: 'Surveillance',
    Tasks: 'Tâches',
    Applications: 'Applications',
    'File Manager': 'Gestionnaire de fichiers',
    'Jobs Platform': "Plateforme d'emploi",
    Messenger: 'Messager',
    'Projects Board': 'Conseil des projets',
    Management: 'La gestion',
    Users: 'Utilisateurs',
    'List All': 'Tout lister',
    'User Profile': "Profil de l'utilisateur",
    Projects: 'Projets',
    Shop: 'Magasin',
    'Products List': 'Liste de produits',
    'View Product': 'Voir le produit',
    'Create Product': 'Créer un produit',
    Invoices: 'Les factures',
    'View Single': 'Voir Single',
    'Auth Pages': "Pages d'authentification",
    Login: 'Connexion',
    Basic: 'De base',
    Cover: 'Couvrir',
    Register: "S'inscrire",
    Wizard: 'Magicien',
    'Recover Password': 'Récupérer mot de passe',
    Status: 'Statut',
    Foundation: 'Fondation',
    'Extra Pages': 'Pages supplémentaires',
    'Error 404': 'Erreur 404',
    'Error 500': 'Erreur 500',
    'Coming Soon': 'À venir',
    Maintenance: 'Maintenance',
    Overview: 'Aperçu',
    'Layout Starters': 'Démarreurs de mise en page',
    'Starter Kit 1': 'Kit de démarrage 1',
    'Starter Kit 2': 'Kit de démarrage 2',
    Documentation: 'Documentation',
    Welcome: 'Bienvenue',
    Help: 'Aider',
    'Features tour': 'Visite des fonctionnalités',
    'Getting started guide': 'Guide de Démarrage',
    'Contact support': 'Contactez le support',
    Version: 'Version',
    Search: 'Chercher',
    Notifications: 'Notifications',
    Settings: 'Paramètres',
    'Language Switcher': 'Sélecteur de langue',
    'Sign out': 'Déconnexion',
    'Change Theme': 'Change le thème',
    'View all notifications': 'Afficher toutes les notifications',
    'Tokyo NextJS Typescript Admin Dashboard':
      "Tableau de bord d'administration de Tokyo React",
    'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
      "Modèle React haute performance construit avec de nombreux composants Material-UI puissants dans plusieurs niches de produits pour des processus de développement d'applications rapides et parfaits",
    'Browse Live Preview': "Parcourir l'aperçu en direct",
    'Live Preview': 'Aperçu en direct',
    'Key Features': 'Principales caractéristiques',
    dashboards: 'tableaux de bord',
    applications: 'applications',
    'management sections': 'sections de gestion',
    'dark/light themes': 'thèmes sombres / clairs',
    components: 'composants',
    'Some of the features that make Tokyo one of the best admin templates available today':
      "Certaines des fonctionnalités qui font de Tokyo l'un des meilleurs modèles d'administration disponibles aujourd'hui",
    'Design Source Files': 'Fichiers source de conception',
    'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
      "Commencez à travailler sur votre projet directement à partir de l'un des kits de démarrage inclus ou utilisez les fichiers Figma / Sketch pour créer d'abord un prototype",
    'We only translated a small part of the template, for demonstration purposes':
      "Nous n'avons traduit qu'une petite partie du modèle, à des fins de démonstration"
  };
  
  export default seJSON;
  