import { lazy } from 'react';
import Authenticated from 'src/components/Molecules/Authenticated/Authenticated';
import Loader from 'src/components/Molecules/Loader';

// Account
const Login = Loader(lazy(() => import('src/pages/Auth/Login')));


const accountRoutes = [
  {
    path: 'login',
    element: <Login />
  },
  // {
  //   path: 'recover-password',
  //   element: <RecoverPassword />
  // },
];

export default accountRoutes;
