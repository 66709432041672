import { ReactNode, useContext } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import WebIcon from '@mui/icons-material/Web';

import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert';
import ImageIcon from '@mui/icons-material/Image';
import BarChartIcon from '@mui/icons-material/BarChart';
import FactoryIcon from '@mui/icons-material/Factory';
import { SettingsContext } from 'src/contexts/SettingsContext';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}


export const developmentMenuItems: MenuItems[] =  [{
  heading: 'Development',
  items: [
    {
      name: 'Old Templates',
      icon: BackupTableTwoToneIcon,
      link: '',
      items: [
      {
        name: 'Blueprints',
        icon: BackupTableTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Extended sidebar',
            link: '/extended-sidebar/dashboards',
            badge: 'v3.0',
            badgeTooltip: 'Added in version 3.1'
          },
          {
            name: 'Accent header',
            link: '/accent-header/dashboards',
            badge: '',
            badgeTooltip: 'Updated'
          },
          {
            name: 'Accent sidebar',
            link: '/dashboards'
          },
          {
            name: 'Boxed sidebar',
            link: '/boxed-sidebar/dashboards'
          },
          {
            name: 'Collapsed sidebar',
            link: '/collapsed-sidebar/dashboards'
          },
          {
            name: 'Bottom navigation',
            link: '/bottom-navigation/dashboards'
          },
          {
            name: 'Top navigation',
            link: '/top-navigation/dashboards'
          }
        ]
      },
      {
        name: 'Dashboards',
        icon: SmartToyTwoToneIcon,
        link: '/dashboards',
        items: [
          {
            name: 'Reports',
            link: 'dashboards/reports'
          },
          {
            name: 'Expenses',
            link: 'dashboards/expenses',
            badge: '',
            badgeTooltip: 'This is a dot tooltip example'
          },
          {
            name: 'Products',
            link: 'dashboards/products'
          },
          {
            name: 'Statistics',
            link: 'dashboards/statistics'
          },
          {
            name: 'Automation',
            link: 'dashboards/automation'
          },
          {
            name: 'Analytics',
            link: 'dashboards/analytics'
          },
          {
            name: 'Banking',
            link: 'dashboards/banking'
          },
          {
            name: 'Commerce',
            link: 'dashboards/commerce'
          },
          {
            name: 'Crypto',
            link: 'dashboards/crypto'
          },
          {
            name: 'Finance',
            link: 'dashboards/finance'
          },
          {
            name: 'Fitness',
            link: 'dashboards/fitness'
          },
          {
            name: 'Healthcare',
            link: '/dashboards/healthcare',
            items: [
              {
                name: 'Doctors',
                link: 'dashboards/healthcare/doctor'
              },
              {
                name: 'Hospital',
                link: 'dashboards/healthcare/hospital'
              }
            ]
          },
          {
            name: 'Helpdesk',
            link: 'dashboards/helpdesk'
          },
          {
            name: 'Learning',
            link: 'dashboards/learning'
          },
          {
            name: 'Monitoring',
            link: 'dashboards/monitoring'
          },
          {
            name: 'Tasks',
            link: 'dashboards/tasks'
          }
        ]
      },
      {
        name: 'Data Display',
        icon: HealthAndSafetyTwoToneIcon,
        badge: '',
        badgeTooltip: 'Tokyo contains over 250 data display blocks',
        link: '/blocks',
        items: [
          {
            name: 'Charts large',
            link: 'blocks/charts-large'
          },
          {
            name: 'Charts small',
            link: 'blocks/charts-small'
          },
          {
            name: 'Composed cards',
            link: 'blocks/composed-cards'
          },
          {
            name: 'Grids',
            link: 'blocks/grids'
          },
          {
            name: 'Icon cards',
            link: 'blocks/icon-cards'
          },
          {
            name: 'Image cards',
            link: 'blocks/image-cards'
          },
          {
            name: 'Lists large',
            link: 'blocks/lists-large'
          },
          {
            name: 'Lists small',
            link: 'blocks/lists-small'
          },
          {
            name: 'Navigation',
            link: 'blocks/navigation'
          },
          {
            name: 'Profile cards',
            link: 'blocks/profile-cards'
          },
          {
            name: 'Progress circular',
            link: 'blocks/progress-circular'
          },
          {
            name: 'Progress horizontal',
            link: 'blocks/progress-horizontal'
          },
          {
            name: 'Sparklines large',
            link: 'blocks/sparklines-large'
          },
          {
            name: 'Sparklines small',
            link: 'blocks/sparklines-small'
          },
          {
            name: 'Statistics',
            link: 'blocks/statistics'
          }
        ]
      },
      {
        name: 'Applications',
        icon: AnalyticsTwoToneIcon,
        link: '/applications',
        items: [
          {
            name: 'Calendar',
            link: 'applications/calendar'
          },
          {
            name: 'File Manager',
            link: 'applications/file-manager'
          },
          {
            name: 'Jobs Platform',
            link: 'applications/jobs-platform'
          },
          {
            name: 'Mailbox',
            link: 'applications/mailbox/inbox'
          },
          {
            name: 'Messenger',
            link: 'applications/messenger'
          },
          {
            name: 'Projects Board',
            link: 'applications/projects-board'
          }
        ]
      },
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: '/management/users',
        items: [
          {
            name: 'List',
            link: 'management/users/list'
          },
          {
            name: 'User Profile',
            link: 'management/users/single'
          }
        ]
      },
      {
        name: 'Projects',
        icon: AccountTreeTwoToneIcon,
        link: '/management/projects/list'
      },
      {
        name: 'Commerce',
        icon: StorefrontTwoToneIcon,
        link: '/management/commerce',
        items: [
          {
            name: 'Shop',
            link: 'management/commerce/shop'
          },
          {
            name: 'List',
            link: 'management/commerce/products/list'
          },
          {
            name: 'Details',
            link: 'management/commerce/products/single/1'
          },
          {
            name: 'Create',
            link: 'management/commerce/products/create'
          }
        ]
      },
      {
        name: 'Invoices',
        icon: ReceiptTwoToneIcon,
        link: '/management/invoices',
        items: [
          {
            name: 'List',
            link: 'management/invoices/list'
          },
          {
            name: 'Details',
            link: 'management/invoices/single'
          },
          {
            name: 'Login',
            items: [
              {
                name: 'Basic',
                link: '/account/login-basic'
              },
              {
                name: 'Cover',
                link: '/account/login-cover'
              }
            ]
          },
          {
            name: 'Register',
            items: [
              {
                name: 'Basic',
                link: '/account/register-basic'
              },
              {
                name: 'Cover',
                link: '/account/register-cover'
              },
              {
                name: 'Wizard',
                link: '/account/register-wizard'
              }
            ]
          },
          {
            name: 'Recover Password',
            link: '/account/recover-password'
          },
          {
            name: 'Error 404',
            link: '/status/404'
          },
          {
            name: 'Error 500',
            link: '/status/500'
          },
          {
            name: 'Maintenance',
            link: '/status/maintenance'
          },
          {
            name: 'Coming Soon',
            link: '/status/coming-soon'
          }
        ]
      },
      {
        name: 'Overview',
        link: '/overview',
        icon: DesignServicesTwoToneIcon
      },
      {
        name: 'Documentation',
        icon: SupportTwoToneIcon,
        link: '/docs'
      }
    ]
  },
  {
    name: 'Testing',
    icon: HealthAndSafetyTwoToneIcon,
    link: '',
    items: [
      {
        name: 'Sales Items Base Demo',
        link: 'testing/sales-items'
      },
      {
        name: 'Table old',
        link: 'testing/sales-items'
      },
      {
        name: 'Olles Testsida',
        link: 'testing/olle'
      },
      {
        name: 'Kalles Testsida',
        link: 'testing/kalle'
      },
      {
        name: 'Kalles Testsida 2',
        link: 'testing/kalle2'
      },
      {
        name: 'Eriks Testsida',
        link: 'testing/erik'
      },
      {
        name: 'Api',
        link: '',
        items: [
          {
            name: 'Api Dynamic Test',
            link: 'testing/api3'
          },
          {
            name: 'Local Api  Test',
            link: 'testing/api'
          },
          {
            name: 'Remote Api  Test',
            link: 'testing/api2'
          },
         
        ]
      },
      
      
    ]
  },
  {
    name: 'Templates',
    icon: WebIcon,
    link: '',
    items: [
      {
        name: 'Cards',
        link: 'templates/cards'
      },
      
      {
        name: 'Tables',
        link: '',
        items: [
          {
            name: 'Sales Item Table',
            link: 'templates/sales-item-table'
          },
          {
            name: 'Sales Order Table',
            link: 'templates/sales-order-table'
          },
         
         
        ]
      },
      {
        name: 'Forms',
        link: '',
        items: [
          {
            name: 'Create Product Template',
            link: 'templates/create-product-form'
          },
          {
            name: 'Template Form',
            link: 'templates/template-form'
          },
         
         
        ]
      },
      {
        name: 'Trees',
        link: '',
        items: [
          {
            name: 'Product Types Tree',
            link: 'templates/product-types-tree'
          },
          {
            name: 'Categories Tree',
            link: 'templates/categories-tree'
          },
         
         
        ]
      },
      
      
    ]
  },
  ]
}];

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Dashboard',
        icon: BackupTableTwoToneIcon,
        link: '/dashboards'
      },
      {
        name: 'Products',
        icon: InventoryIcon,
        link: '',
        items: [
          
          {
            name: 'Products',
            link: '/products/products',
            icon: InventoryIcon,
          },
          {
            name: 'Product Types',
            link: '/products/product-types',
            icon: AccountTreeIcon,
          },
          {
            name: 'Sales Items',
            link: '/products/sales-items',
            icon: ShoppingCartIcon,
          },
          {
            name: 'Categories',
            link: '/products/categories',
            icon: AccountTreeIcon,
          }
          ,
          {
            name: 'Supplier Products',
            link: '/products/supplier-products',
            icon: WarehouseIcon,
          }
          ,
          {
            name: 'Manufacturers',
            link: '/products/manufacturers',
            icon: FactoryIcon,
          }
          ,
          {
            name: 'Images and other resources',
            link: '/products/resources',
            icon: ImageIcon,
          }
         
        ]
      },
      {
        name: 'Orders',
        icon: ReceiptIcon,
        link: '',
        items: [
          {
            name: 'Orders',
            link: '/orders/orders',
            icon: ReceiptIcon
          },
          {
            name: 'Returns',
            link: '/orders/returns',
            icon: AssignmentReturnIcon
          }
        ]
      },
      {
        name: 'Purchasing',
        icon: RequestPageIcon,
        link: '',
        items: [
          {
            name: 'Purchase Order',
            link: '/purchasing/purchase-order',
            icon: RequestPageIcon
          },
          {
            name: 'Purchase Order Draft',
            link: '/purchasing/purchase-order-draft',
            icon: NoteAddIcon
          },
          {
            name: 'Low Order Points',
            link: '/purchasing/low-order-points',
            icon: SdCardAlertIcon
          },
          {
            name: 'Suppliers',
            link: '/purchasing/suppliers',
            icon: WarehouseIcon
          }
        ]
      },
      {
        name: 'Reports',
        icon: BarChartIcon,
        link: '',
        items: [
          {
            name: 'Sales Reports',
            icon: BarChartIcon,
            link: '/reports/sales-reports',
            items: [
              {
                name: 'Sales Order Report',
                icon: BarChartIcon,
                link: '/reports/sales-reports/sales-order-reports'
              },
              {
                name: 'Sales Items Sales',
                icon: BarChartIcon,
                link: '/reports/sales-reports/sales-items-sales'
              }
            ]
          },
          {
            name: 'Campaign Reports',
            icon: BarChartIcon,
            link: '/reports/campaign-reports',
            items: [
              {
                name: 'Sales Order Report',
                icon: BarChartIcon,
                link: '/reports/campaign-reports/sales-order-report'
              },
              {
                name: 'Sales Items Sales',
                icon: BarChartIcon,
                link: '/reports/campaign-reports/sales-items-report'
              }
            ]
          },
          {
            name: 'Product Recieve Report',
            icon: BarChartIcon,
            link: '/reports/product-recieve-report'

          },
          {
            name: 'Customer Return Orders',
            icon: BarChartIcon,
            link: '/reports/customer-return-order-report'
          },

        ]
      }
    ]
  },








  {
    heading: 'Settings',
    items: [
      {
        name: 'Webclient settings',
        icon: SettingsIcon,
        link: '/settings/webclient'
      },
      {
        name: 'Integration settings',
        icon: SettingsIcon,
        link: '/settings/integrations'
      },
      {
        name: 'Accounts',
        icon: PersonIcon,
        items: [
          {
            name: 'All users',
            icon: PeopleIcon,
            link: '/management/users/list'
          },
          {
            name: 'Register new user',
            icon: PersonAddIcon,
            link: '/accounts/register'
          }
        ]
      },
      
    ]
  }






  
];

export default menuItems;
