import { Box, TablePagination } from "@mui/material";

const defaultRowsPerPageOptions = [10,20,40,100];

export const CustomTablePagination = (
    {
        sortedDataItemsLength,
        handlePageChange,
        handleLimitChange,
        onePageTable,
        page = 1,
        limit = 10,
    }
) => {
    return(
    <Box>
    {(onePageTable ?? false) ? null : <TablePagination
        component="div"
        count={sortedDataItemsLength}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={defaultRowsPerPageOptions}
    />}
</Box>);
}