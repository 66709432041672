import PageHeader, { PageHeaderProps } from 'src/components/Organisms/PageHeader';
import Footer from 'src/components/Molecules/Footer/Footer';
import { Grid } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ActionButton from 'src/components/Atoms/Buttons/ActionButton';

const pageHeaderData: PageHeaderProps = {
    title: "Sales Items Sales ",
    subTitle: "View and edit of Sales Items Sales",
    components: [{component:<ActionButton title="Action Button" icon={<AddTwoToneIcon />} actionUrl="/management/commerce/products/create" />}]
}



function SalesItemsSales() {
    
    return (
        <>
            <PageHeader  {...pageHeaderData} />

            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                Sales Items Sales
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}

export default SalesItemsSales;
