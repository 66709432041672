import { FullTableProps } from 'src/components/Organisms/FullTable';
import useTable from './useTable';
import { SalesOrders } from 'src/models/sales_order';
 
const tableColumns = [
  { id: "sales_order", shown: true },
  { id: "status", shown: true },
  { id: "toPay", shown: true },
  { id: "sales_organization", shown: true },
  { id: "currency", shown: true },
  { id: "shipping_method", shown: true },
  { id: "payment_method", shown: true },
  { id: "payment_terms", shown: true },
  { id: "customer", shown: true },
  { id: "user", shown: true },
  { id: "total_amount_net", shown: true },
  { id: "total_tax", shown: true },
  { id: "total_amount_gross", shown: true },
  { id: "tax_included", shown: true },
  { id: "created", shown: true },
  { id: "released", shown: true },
  { id: "fulfilled", shown: true },


];

const tableSettings: FullTableProps = {
  tableId: "salesOrder",
  id_column: "sales_order",
  serachColumns: ["user","customer"],
  editLink: "/orders/order/edit?id="
}

const useSalesOrdersTable = (): [SalesOrders[], FullTableProps, any[]] => {
  return useTable(tableSettings, tableColumns, '/api/salesOrders', "salesOrders");
};
export default useSalesOrdersTable;