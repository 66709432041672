import { useState, useEffect, useCallback, FC } from 'react';
import axios from 'src/utils/axios';
import PageHeader, { PageHeaderProps } from 'src/components/Organisms/PageHeader';

import Footer from 'src/components/Molecules/Footer/Footer';
import useRefMounted from 'src/hooks/useRefMounted';
import { SalesItem } from 'src/models/sale_item';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ActionButton from 'src/components/Atoms/Buttons/ActionButton';
import FullTable from 'src/components/Organisms/FullTable';
import { CreateHeaderColumns } from 'src/components/Organisms/FullTable';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/Organisms/ContentCard';
import { useDispatch, useSelector } from 'src/store';
import { addTable, toggleTableColumn, OneColumnState } from 'src/slices/tables';
import TableColumnSettings from 'src/components/Molecules/Table/TableColumnSettings';
import { FullTableProps } from 'src/components/Organisms/FullTable';
import { Product } from 'src/models/product';
import { PurchaseOrder } from 'src/models/purchase_order';



const tableSettings: FullTableProps ={ 
    tableId:"purcahseOrderTable",
    id_column: "order_no",
    serachColumns: ["our_contact","status"]

}


const pageHeaderData: PageHeaderProps = {
    title: "Purchase Orders",
    subTitle: "Orders from us to a supplier",
    components: [
        {component:<ActionButton title="New Purchase Order" icon={<AddTwoToneIcon />} actionUrl="/management/commerce/products/create" />},
        {isInGroupedMenu:true, component:<ActionButton title="Edit Columns"  actionType="showHiddenCard" values="PurchaseOrderColumsEditor" variant='text' />},
    ],

}




function ProductTable() {
    const isMountedRef = useRefMounted();
    const dispatch = useDispatch();
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
    const getPurchaseOrders = useCallback(async () => {
        try {
            const response = await axios.get<{ purchaseOrders: PurchaseOrder[] }>(
                '/api/purchase-orders'
            );
            if (isMountedRef()) {
                console.log(response.data);
                setPurchaseOrders(response.data.purchaseOrders);
                const columns: OneColumnState[] = CreateHeaderColumns(response.data.purchaseOrders);
                dispatch(addTable({ id: tableSettings.tableId, columns: columns }));
            }
        } catch (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        }
    }, [isMountedRef()]);
    useEffect(() => {
        getPurchaseOrders();
    }, [getPurchaseOrders]);
    let headerColumns = CreateHeaderColumns(purchaseOrders);
    const { tables } = useSelector(
        (state) => state.tables
    );
    headerColumns = tables?.find(table => table.id == tableSettings.tableId)?.columns ?? [{ id: "", shown: false }];

    return (
        <>
            <PageHeader  {...pageHeaderData} />
            <ContentCardsContainer >
                <ContentCard {...{
                    cardId: "PurchaseOrderColumsEditor",
                    cardTitleText: "Show and hide columns",
                   cardTypes: [CardType.hideable]
                }}>
                    <TableColumnSettings columns={headerColumns} tableId={tableSettings.tableId}></TableColumnSettings>
                </ContentCard>
                <ContentCard {...{
                    cardId: "Products",
                    cardTypes: [CardType.noHeader],
                    contentPadding: 0
                }}>
                    <FullTable tableData={purchaseOrders}{...tableSettings} />
                </ContentCard>
            </ContentCardsContainer>

            <Footer />
        </>
    );
}

export default ProductTable;
