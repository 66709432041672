import { mock } from 'src/utils/axios';
import { SalesItem } from 'src/models/sale_item';

let salesItems: SalesItem[] = [{"status":"Active","sales_item_id":106597,"sales_item":"Tall Eye Putty silk shade","manufacturer":"Frank & Cordinata","manufacturer_prod_id":"22\" Tall Eye Putty silk shad","product_type":"Bordslampor","created":"2015-05-05T06:18:12Z","modified":"2015-05-05T06:18:12Z"},
{"status":"Active","sales_item_id":102360,"sales_item":"A set of three matching chargers","manufacturer":"Van Thiel/The Finishing Touch","manufacturer_prod_id":"VT10571-01","product_type":"Dekorationsföremål","created":"2012-10-21 22:36","modified":"2019-01-11 10:56"},
{"status":"Active","sales_item_id":110146,"sales_item":"Adelaide Coffee Cup small","manufacturer":"Astier de Villatte","manufacturer_prod_id":"TSSADL1","product_type":"Kaffekoppar","created":"2017-09-19 17:1","modified":"2018-11-28 15:48"},
{"status":"Active","sales_item_id":110148,"sales_item":"Adelaide Large Oval Platter","manufacturer":"Astier de Villatte","manufacturer_prod_id":"PLTADL5","product_type":"Mattallrikar","created":"2017-09-19 17:1","modified":"2018-11-28 15:49"},
{"status":"Active","sales_item_id":110170,"sales_item":"Adélaïde Large Pitch","manufacturer":"Astier de Villatte","manufacturer_prod_id":"VSEADL3","product_type":"Kannor och karaffer","created":"2017-09-19 17:1","modified":"2018-11-28 15:53"},
{"status":"Active","sales_item_id":110145,"sales_item":"Adelaide Saucer","manufacturer":"Astier de Villatte","manufacturer_prod_id":"SSCADL2","product_type":"Uppläggningsfat","created":"2017-09-19 17:1","modified":"2018-11-29 10:09"},
{"status":"Active","sales_item_id":110147,"sales_item":"Adelaide Saucer","manufacturer":"Astier de Villatte","manufacturer_prod_id":"SSCADL1","product_type":"Uppläggningsfat","created":"2017-09-19 17:1","modified":"2018-11-29 10:06"},
{"status":"Active","sales_item_id":110154,"sales_item":"Adélaïde Small Pitch","manufacturer":"Astier de Villatte","manufacturer_prod_id":"VSEADL1","product_type":"Kannor och karaffer","created":"2017-09-19 17:1","modified":"2018-11-29 10:00"},
{"status":"Active","sales_item_id":111501,"sales_item":"Adventskalender kola i choklad 2019","manufacturer":"Pärlans konfekty","manufacturer_prod_id":"950","product_type":"Godis","created":"2018-08-24 10:0","modified":"2019-05-09 10:35"},
{"status":"Discontinued","sales_item_id":111443,"sales_item":"Adventsljusstake Näve","manufacturer":"Oscar & Clothilde","manufacturer_prod_id":"SA-1560SB","product_type":"Ljussta","created":"2018-08-23 13:0","modified":"2018-10-29 11:52"},
{"status":"Active","sales_item_id":110697,"sales_item":"Ägg glas med dot 3x4cm 10-pack","manufacturer":"A LOT DECORATION","manufacturer_prod_id":"24472","product_type":"Dekorationsför","created":"2018-01-25 13:4","modified":"2019-03-14 14:46"},
{"status":"Active","sales_item_id":111284,"sales_item":"Äggkopp And","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1321","product_type":"Äggkoppar","created":"2018-04-27 14:4","modified":"2018-05-08 19:27"},
{"status":"Active","sales_item_id":110623,"sales_item":"Äggkopp åsna","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1387/br","product_type":"Äggkoppar","created":"2018-01-10 09:5","modified":"2018-02-20 08:47"},
{"status":"Active","sales_item_id":112362,"sales_item":"Äggkopp Bläckfisk guld","manufacturer":"Oscar & Clothilde","manufacturer_prod_id":"26706-bpl","product_type":"Äggkoppar","created":"2019-04-02 09:3","modified":"2019-04-02 09:32"},
{"status":"Active","sales_item_id":111057,"sales_item":"Äggkopp Duckfeet","manufacturer":"Serax","manufacturer_prod_id":"B9709042","product_type":"Äggkoppar","created":"2018-03-08 10:2","modified":"2018-03-08 10:29"},
{"status":"Inactive","sales_item_id":111768,"sales_item":"Äggkopp Flodhäst ","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1650","product_type":"Äggkoppar","created":"2018-10-26 12:4","modified":"2018-10-26 12:49"},
{"status":"Active","sales_item_id":110622,"sales_item":"Äggkopp Get ","manufacturer":"Quail Ceramics","manufacturer_prod_id":"678","product_type":"Äggkoppar","created":"2018-01-10 09:3","modified":"2018-02-20 08:49"},
{"status":"Active","sales_item_id":111716,"sales_item":"Äggkopp Giraff","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1863","product_type":"Äggkoppar","created":"2018-09-28 10:0","modified":"2018-09-28 10:00"},
{"status":"Active","sales_item_id":110625,"sales_item":"Äggkopp Hare","manufacturer":"Quail Ceramics","manufacturer_prod_id":"348","product_type":"Äggkoppar","created":"2018-01-10 10:1","modified":"2018-03-07 09:52"},
{"status":"Active","sales_item_id":111287,"sales_item":"Äggkopp Leopard ","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1795","product_type":"Äggkoppar","created":"2018-04-27 15:0","modified":"2018-05-08 19:28"},
{"status":"Active","sales_item_id":111281,"sales_item":"Äggkopp Millie katt","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1771/MI","product_type":"Äggkoppar","created":"2018-04-27 14:2","modified":"2018-05-08 19:21"},
{"status":"Active","sales_item_id":110616,"sales_item":"Äggkopp Mops","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1656/f","product_type":"Äggkoppar","created":"2018-01-09 16:2","modified":"2018-03-06 15:35"},
{"status":"Active","sales_item_id":111767,"sales_item":"Äggkopp Räv","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1545","product_type":"Äggkoppar","created":"2018-10-26 12:4","modified":"2018-10-26 12:45"},
{"status":"Active","sales_item_id":112361,"sales_item":"Äggkopp Tassar guld","manufacturer":"Oscar & Clothilde","manufacturer_prod_id":"26705-bpl","product_type":"Äggkoppar","created":"2019-04-02 09:2","modified":"2019-04-02 09:45"},
{"status":"Active","sales_item_id":111283,"sales_item":"Äggkopp Uggla","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1629","product_type":"Äggkoppar","created":"2018-04-27 14:4","modified":"2018-05-08 19:25"},
{"status":"Active","sales_item_id":110624,"sales_item":"Äggkopp Vild kanin","manufacturer":"Quail Ceramics","manufacturer_prod_id":"522","product_type":"Äggkoppar","created":"2018-01-10 10:0","modified":"2018-03-07 09:51"},
{"status":"Active","sales_item_id":111282,"sales_item":"Äggkopp Vincent katt","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1771/vin","product_type":"Äggkoppar","created":"2018-04-27 14:4","modified":"2018-05-08 19:26"},
{"status":"Active","sales_item_id":111288,"sales_item":"Äggkopp Zebra ","manufacturer":"Quail Ceramics","manufacturer_prod_id":"1838","product_type":"Äggkoppar","created":"2018-04-27 15:0","modified":"2018-05-08 19:24"},
{"status":"Active","sales_item_id":110153,"sales_item":"Alexander Large Saucer","manufacturer":"Astier de Villatte","manufacturer_prod_id":"SSCALX3","product_type":"Uppläggningsfat","created":"2017-09-19 17:1","modified":"2018-11-29 10:42"},
{"status":"Active","sales_item_id":110144,"sales_item":"Alexandre Dinner Plate","manufacturer":"Astier de Villatte","manufacturer_prod_id":"ASPALX3","product_type":"Mattallrikar","created":"2017-09-19 17:1","modified":"2018-11-29 10:15"},
{"status":"Active","sales_item_id":111068,"sales_item":"Alexandre Vegetable Platter","manufacturer":"Astier de Villatte","manufacturer_prod_id":"LGMALX1","product_type":"Uppläggningsfat","created":"2018-03-08 16:0","modified":"2018-11-29 09:37"},
{"status":"Active","sales_item_id":103062,"sales_item":"Andos adjustable standard lamp","manufacturer":"Tierlantijn BV","manufacturer_prod_id":"L.075.9.820","product_type":"Lampfö","created":"2013-01-16 00:4","modified":"2016-04-04 15:17"},
{"status":"Active","sales_item_id":111278,"sales_item":"Andrew armstödsskydd 1 par","manufacturer":"Bröderna Andersso","manufacturer_prod_id":"Andrew armstödsskydd v.a.","product_type":"Sof","created":"2018-04-25 10:3","modified":"2018-04-25 10:30"},
{"status":"Active","sales_item_id":111277,"sales_item":"Andrew armstödsskydd 1 par vid soffbeställni","manufacturer":"Bröderna Andersso","manufacturer_prod_id":"Andrew armstödsskyd","product_type":"Sof","created":"2018-04-25 10:2","modified":"2018-10-31 14:07"},
{"status":"Active","sales_item_id":111446,"sales_item":"änglaspel Lusse","manufacturer":"PLUTO Produkter AB","manufacturer_prod_id":"AN314","product_type":"","created":"2018-08-23 13:5","modified":"2018-08-23 13:57"},
{"status":"Active","sales_item_id":111445,"sales_item":"änglaspel Snöflinga","manufacturer":"PLUTO Produkter AB","manufacturer_prod_id":"AN106","product_type":"","created":"2018-08-23 13:5","modified":"2018-08-23 13:51"},
{"status":"Active","sales_item_id":112706,"sales_item":"änglaspel Stjärna","manufacturer":"PLUTO Produkter AB","manufacturer_prod_id":"AN151","product_type":"","created":"2019-08-27 10:2","modified":"2019-08-27 10:30"},
{"status":"Active","sales_item_id":112257,"sales_item":"Anteckningsbok Butterflies","manufacturer":"Laurence King Publishing Ltd","manufacturer_prod_id":"9781616896911","product_type":"Anteckningsböcker","created":"2019-03-01 11:2","modified":"2019-03-01 11:20"},
{"status":"Active","sales_item_id":109996,"sales_item":"Anteckningsbok fashionary candy camo","manufacturer":"LITTLE HAMPTON","manufacturer_prod_id":"9789887710899","product_type":"Anteckningsböcker","created":"2017-09-13 10:3","modified":"2018-04-26 15:07"},
{"status":"Active","sales_item_id":109998,"sales_item":"Anteckningsbok fashionary think of me","manufacturer":"LITTLE HAMPTON","manufacturer_prod_id":"9789887710882","product_type":"Anteckningsböcker","created":"2017-09-13 10:4","modified":"2017-09-13 10:47"},
{"status":"Active","sales_item_id":112256,"sales_item":"Anteckningsbok Home","manufacturer":"Laurence King Publishing Ltd","manufacturer_prod_id":"9781616896379","product_type":"Anteckningsböcker","created":"2019-03-01 11:1","modified":"2019-03-01 11:15"},
{"status":"Active","sales_item_id":111534,"sales_item":"Anteckningsbok ORKIDÉ","manufacturer":"Sköna Tin","manufacturer_prod_id":"STNOTE 50","product_type":"Anteckningsböcker","created":"2018-08-24 14:2","modified":"2018-08-24 14:20"},
{"status":"Active","sales_item_id":111138,"sales_item":"Anteckningsbok Parisian Chic blå mella","manufacturer":"LITTLE HAMPTON","manufacturer_prod_id":"9782080203533","product_type":"Anteckningsböcker","created":"2018-03-20 16:0","modified":"2018-04-19 11:38"},
{"status":"Active","sales_item_id":111136,"sales_item":"Anteckningsbok Parisian Chic blå sto","manufacturer":"LITTLE HAMPTON","manufacturer_prod_id":"9782080203519","product_type":"Anteckningsböcker","created":"2018-03-20 15:4","modified":"2018-03-20 15:49"},
{"status":"Active","sales_item_id":111135,"sales_item":"Anteckningsbok Parisian Chic röd sto","manufacturer":"LITTLE HAMPTON","manufacturer_prod_id":"9782080203564","product_type":"Anteckningsböcker","created":"2018-03-20 15:4","modified":"2018-03-20 16:11"},
{"status":"Active","sales_item_id":111139,"sales_item":"Anteckningsbok pass Parisian Chic rö","manufacturer":"LITTLE HAMPTON","manufacturer_prod_id":"9782080203540","product_type":"Anteckningsböcker","created":"2018-03-20 16:2","modified":"2018-04-19 11:38"},
{"status":"Active","sales_item_id":111537,"sales_item":"Anteckningsbok ROS","manufacturer":"Sköna Tin","manufacturer_prod_id":"STNOTE 57","product_type":"Anteckningsböcker","created":"2018-08-24 14:2","modified":"2018-08-24 14:26"},
{"status":"Active","sales_item_id":112255,"sales_item":"Anteckningsbok Trees","manufacturer":"Laurence King Publishing Ltd","manufacturer_prod_id":"9781616895372","product_type":"Anteckningsböcker","created":"2019-03-01 11:0","modified":"2019-03-01 11:07"},
{"status":"Active","sales_item_id":107070,"sales_item":"Antik Barockbyrå","manufacturer":"LASEROW ANTIK","manufacturer_prod_id":"LA.barockbyrä","product_type":"Byr","created":"2015-03-03 08:2","modified":"2015-03-03 08:24"}];

mock.onGet('/api/salesitems').reply(() => {
  return [200, { salesItems }];
});

mock.onGet('/api/salesitem').reply((config) => {
  const  salesItemId  = parseInt(config.params.params.sales_item_id);
  const salesItem = salesItems.find((_salesItem) => _salesItem.sales_item_id === salesItemId);
  return [200, { salesItem }];
});

//funkar men pga ej sparad i lista så uppdateras inte värdet såklart. 

mock.onPost('/api/salesitem/update').reply((config) => {
  console.log("wie")
 
  const  salesItem  = JSON.parse(config.data).salesItem;
  console.log(salesItems.length)
  console.log(salesItem)
  console.log(salesItems[salesItems.indexOf(salesItems.find((_salesItem) => _salesItem.sales_item_id === salesItem.sales_item_id))])
  if(salesItems[salesItems.indexOf(salesItems.find((_salesItem) => _salesItem.sales_item_id === salesItem.sales_item_id))]){
    salesItems[salesItems.indexOf(salesItems.find((_salesItem) => _salesItem.sales_item_id === salesItem.sales_item_id))]=salesItem
  }else{
    
    salesItems.push(salesItem)
  }
  salesItems[salesItems.indexOf(salesItems.find((_salesItem) => _salesItem.sales_item_id === salesItem.sales_item_id))]=salesItem
  console.log(salesItems[salesItems.indexOf(salesItems.find((_salesItem) => _salesItem.sales_item_id === salesItem.sales_item_id))])
  console.log(salesItems.length)
  return [200,  "salesItem"];
});
