import { useDispatch, useSelector } from 'src/store';
import { CreateHeaderColumns } from 'src/components/Organisms/FullTable';
import { addTable } from 'src/slices/tables';
import { useEffect } from 'react';
import { addCardState, hideCardById } from 'src/slices/cards';

const useMountEffect = (fun) => useEffect(fun, []);

const useCardState = (cardId, hiddenCard, hiddenInitally): [boolean, Function] => {
  const dispatch = useDispatch();
  function addCardToRedux() {
    dispatch(addCardState({ id: cardId, isCardVisible: !hiddenInitally  }));
  }
  const hideCard = () => {
    dispatch(hideCardById(cardId));
  }
   
  let isCardVisible = true;
  if (hiddenCard) {
    useMountEffect(addCardToRedux);
    const { cards } = useSelector(
      (state) => state.cards
    );
    isCardVisible = cards.find(card => card.id == cardId)?.isCardVisible ?? true;
  }
  return [isCardVisible, hideCard]
};

export default useCardState;
