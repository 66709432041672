import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { SettingsProvider } from './contexts/SettingsContext';
import SuspenseLoader from './components/Molecules/SuspenseLoader';
import DashboardPrivider from './contexts/DashboardContext';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  return (
    <ThemeProvider>
      <SettingsProvider>
        <DashboardPrivider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
              maxSnack={6}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <CssBaseline />
              {auth.isInitialized ? content : <SuspenseLoader />}
            </SnackbarProvider>
          </LocalizationProvider>
        </DashboardPrivider>
      </SettingsProvider>
    </ThemeProvider>
  );
}
export default App;
function isMountedRef() {
  throw new Error('Function not implemented.');
}

