

import { SalesOrders } from 'src/models/sales_order';
import { mock } from 'src/utils/axios';



let salesOrders: SalesOrders[] = [{
    "sales_order": 2127564,
    "status": "Fulfilled",
    "toPay": 745,
    "sales_organization": "Demo Company",
    "currency": "SEK",
    "shipping_method": "MyPack",
    "payment_method": "Invoice",
    "payment_terms": "Net 10 Days",
    "customer": 6036020,
    "customer_name": "ERIK EKBLAD",
    "user": "ERIK EKBLAD",
    "total_amount_net": 596,
    "total_tax": 149,
    "total_amount_gross": 745,
    "tax_included": true,
    "created": "2022-08-04 14.03.23",
    "released": "2022-08-04 14.05.37",
    "fulfilled": "2022-08-04 14.07.17"
},
    {
        "sales_order": 2127554,
        "status": "Fulfilled",
        "toPay": 758,
        "sales_organization": "Demo Company",
        "currency": "SEK",
        "shipping_method": "MyPack",
        "payment_method": "KlarnaSE",
        "payment_terms": "Net 10 Days",
        "customer": 6036058,
        "customer_name": "Vendre Guest Account",
        "user": "Vendre Guest Account",
        "total_amount_net": 606.4,
        "total_tax": 151.6,
        "total_amount_gross": 758,
        "tax_included": true,
        "created": "2022-08-01 09.40.01",
        "released": "2022-08-01 09.40.01",
        "fulfilled": "2022-08-01 10.24.32"
    },
    {
        "sales_order": 2127553,
        "status": "Fulfilled",
        "toPay": 2495,
        "sales_organization": "Demo Company",
        "currency": "SEK",
        "shipping_method": "MyPack",
        "payment_method": "Invoice",
        "payment_terms": "Net 10 Days",
        "customer": 6036020,
        "customer_name": "ERIK EKBLAD1",
        "user": "ERIK EKBLAD",
        "total_amount_net": 596,
        "total_tax": 149,
        "total_amount_gross": 745,
        "tax_included": true,
        "created": "2022-08-04 14.03.23",
        "released": "2022-08-04 14.05.37",
        "fulfilled": "2022-08-04 14.07.17"
    },
    {
        "sales_order": 21275641,
        "status": "Fulfilled",
        "toPay": 745,
        "sales_organization": "Demo Company",
        "currency": "SEK",
        "shipping_method": "MyPack",
        "payment_method": "Invoice",
        "payment_terms": "Net 10 Days",
        "customer": 6036020,
        "customer_name": "ERIK EKBLAD",
        "user": "ERIK EKBLAD",
        "total_amount_net": 596,
        "total_tax": 149,
        "total_amount_gross": 745,
        "tax_included": true,
        "created": "2022-08-04 14.03.23",
        "released": "2022-08-04 14.05.37",
        "fulfilled": "2022-08-04 14.07.17"
    },
    {
        "sales_order": 21275642,
        "status": "Fulfilled",
        "toPay": 745,
        "sales_organization": "Demo Company",
        "currency": "SEK",
        "shipping_method": "MyPack",
        "payment_method": "Invoice",
        "payment_terms": "Net 10 Days",
        "customer": 6036020,
        "customer_name": "ERIK EKBLAD",
        "user": "ERIK EKBLAD",
        "total_amount_net": 596,
        "total_tax": 149,
        "total_amount_gross": 745,
        "tax_included": true,
        "created": "2022-08-04 14.03.23",
        "released": "2022-08-04 14.05.37", "fulfilled": "2022-08-04 14.07.17"
    },
    {
        "sales_order": 212756412,
        "status": "Return",
        "toPay": 745,
        "sales_organization": "Demo Company",
        "currency": "SEK",
        "shipping_method": "MyPack",
        "payment_method": "Invoice",
        "payment_terms": "Net 10 Days",
        "customer": 6036020,
        "customer_name": "ERIK EKBLAD",
        "user": "ERIK EKBLAD",
        "total_amount_net": 596,
        "total_tax": 149,
        "total_amount_gross": 745,
        "tax_included": true,
        "created": "2022-08-04 14.03.23",
        "released": "2022-08-04 14.05.37", "fulfilled": "2022-08-04 14.07.17"
    }
    ,
    {
        "sales_order": 2512756412,
        "status": "Return",
        "toPay": 745,
        "sales_organization": "Demo Company",
        "currency": "SEK",
        "shipping_method": "MyPack",
        "payment_method": "Invoice",
        "payment_terms": "Net 10 Days",
        "customer": 6036020,
        "customer_name": "ERIK EKBLAD",
        "user": "ERIK EKBLAD",
        "total_amount_net": 596,
        "total_tax": 149,
        "total_amount_gross": 745,
        "tax_included": true,
        "created": "2022-08-04 14.03.23",
        "released": "2022-08-04 14.05.37", "fulfilled": "2022-08-04 14.07.17"
    }
    ,
    {
        "sales_order": 251275412,
        "status": "Return",
        "toPay": 745,
        "sales_organization": "Demo Company",
        "currency": "SEK",
        "shipping_method": "MyPack",
        "payment_method": "Invoice",
        "payment_terms": "Net 10 Days",
        "customer": 6036020,
        "customer_name": "ERIK EKBLAD",
        "user": "ERIK EKBLAD",
        "total_amount_net": 596,
        "total_tax": 149,
        "total_amount_gross": 745,
        "tax_included": true,
        "created": "2022-08-04 14.03.23",
        "released": "2022-08-04 14.05.37", "fulfilled": "2022-08-04 14.07.17"
    }
    ,
    {
        "sales_order": 25756412,
        "status": "Return",
        "toPay": 745,
        "sales_organization": "Demo Company",
        "currency": "SEK",
        "shipping_method": "MyPack",
        "payment_method": "Invoice",
        "payment_terms": "Net 10 Days",
        "customer": 6036020,
        "customer_name": "ERIK EKBLAD",
        "user": "ERIK EKBLAD",
        "total_amount_net": 596,
        "total_tax": 149,
        "total_amount_gross": 745,
        "tax_included": true,
        "created": "2022-08-04 14.03.23",
        "released": "2022-08-04 14.05.37", "fulfilled": "2022-08-04 14.07.17"
    }

]


mock.onGet('/api/salesOrders').reply(() => {
    return [200, { salesOrders }];
  });
  
  
  mock.onGet('/api/order').reply((config) => {
  
    const  salesOrderId  = parseInt(config.params.params.sales_order);
  
    const salesItem = salesOrders.find((_salesItem) => _salesItem.sales_order === salesOrderId);
  
    return [200, { salesItem }];
  });
