import PageHeader, { PageHeaderProps } from 'src/components/Organisms/PageHeader';

import Footer from 'src/components/Molecules/Footer/Footer';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ActionButton from 'src/components/Atoms/Buttons/ActionButton';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import ContentCardsContainer, { CardSectionHeader, CardType, ContentCard } from 'src/components/Organisms/ContentCard';
import { alpha, Avatar, Box, Button, Card, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, styled, Typography, useTheme } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React, { FC } from 'react';
import { ToggleDensePadding } from '../Settings/WebclientSettings';
import Scrollbar from 'src/components/Atoms/Scrollbar/Scrollbar';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import AutoAwesomeMosaicTwoToneIcon from '@mui/icons-material/AutoAwesomeMosaicTwoTone';
import ContactPhoneTwoToneIcon from '@mui/icons-material/ContactPhoneTwoTone';
import EvStationTwoToneIcon from '@mui/icons-material/EvStationTwoTone';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import OndemandVideoTwoToneIcon from '@mui/icons-material/OndemandVideoTwoTone';
import { useTranslation } from 'react-i18next';

const pageHeaderData: PageHeaderProps = {
    title: "Overview of Product and Sales Item pages",
    subTitle: "This page contains links to all the different pages that manage product. It also includes tips and instructions, especially for new functionality so be sure to check back",
    
}

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
      border-radius: 100px;
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
  
      .MuiSvgIcon-root {
          transform-origin: center;
          transform: scale(1);
          transition: ${theme.transitions.create(['transform'])};
      }
  
      &:hover {
          .MuiSvgIcon-root {
              transform: scale(1.4);
          }
      }
    `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.5, 1)};
    `
);



export const PageLinksContent: FC<{ links?}> = ({ links }) => {
    const pageLinks = [
        { title: "Products Table", url: "/products/products", description: "This page contains a table showing all products. Products are purchased from suppliers and handled in the warehouse physically. They contain attributes that depend on their assigned Product Type. Products are never sold in a physical of online-store. That is for Sales Items, which can contain one or more Products." },
        { title: "Create Products", url: "/management/commerce/products/create", description: "This page is used to create a New Product" },
        { title: "Product Types", url: "/products/product-types", description: "Product Types affect what Attributes are availible for each product. You can have a mapping from Product Types to Categories but they do not need to be the same" },
        { title: "Sales Items Table", url: "/products/sales-items", description: "Sales Items are different from Products. They are sold. One Sales Item can contain one or more Products. A lamp shade combined with a lamp base could be two different Products but sold as one Sales Item. The lamp base and shade can then also be sold seperatly as different Sales Items" },
        { title: "Create Sales Items", url: "/products/sales-items/create", description: "This page is used to create a New Sales Item" },
        { title: "Categories", url: "/products/categories", description: "Categories are for Sales Items. Categories are used online and for the in-store system as a way to organize the Sales Items. One Sales Item can be put in multiple Categories" },
        { title: "Supplier Products", url: "/products/supplier-products", description: "Supplier Products are used a step before a full product is created. Supplier products can be very minimal and there can be a lot of them before some are chosen and converted to full Products with all the attributes and such" },
        { title: "Manufacturers", url: "/products/manufacturers", description: "Every Product need a Manufacturer " },
        { title: "Images and other Resources", url: "/products/resources", description: "Images as well as pdf's containing product manuals and such are all so called resources. These can be created and added in the Sales Items Create-view but they can also be viwed and managed from this page. " },
    ];
    return (
        <>
            <Grid container direction="row" justifyContent="flex-start" spacing={2} px={2} alignItems="stretch">
                {pageLinks.map((pageLink, i) => (
                    <Grid key={i} item p={2} md={4} textAlign={'justify'}>
                        <Grid item p={2} textAlign={'justify'}>

                            <h3>{pageLink.title}
                                <IconButton
                                    component={RouterLink}
                                    to={pageLink.url}
                                    color="primary"
                                >
                                    <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                            </h3>
                            <Divider></Divider>
                            <p >{pageLink.description}</p>

                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
export const PageLinksContent2: FC<{ links?}> = ({ links }) => {
    const pageLinks = [
        { title: "Products Table", url: "/products/products", description: "This page contains a table showing all products. Products are purchased from suppliers and handled in the warehouse physically. They contain attributes that depend on their assigned Product Type. Products are never sold in a physical or online-store. That is for Sales Items, which can contain one or more Products." },
        { title: "Create Products", url: "/management/commerce/products/create", description: "This page is used to create a New Product" },
        { title: "Product Types", url: "/products/product-types", description: "Product Types affect what Attributes are availible for each product. You can have a mapping from Product Types to Categories but they do not need to be the same" },
        { title: "Sales Items Table", url: "/products/sales-items", description: "Sales Items are different from Products. They are sold. One Sales Item can contain one or more Products. A lamp shade combined with a lamp base could be two different Products but sold as one Sales Item. The lamp base and shade can then also be sold seperatly as different Sales Items" },
        { title: "Create Sales Items", url: "/products/sales-items/create", description: "This page is used to create a New Sales Item" },
        { title: "Categories", url: "/products/categories", description: "Categories are for Sales Items. Categories are used online and for the in-store system as a way to organize the Sales Items. One Sales Item can be put in multiple Categories" },
        { title: "Supplier Products", url: "/products/supplier-products", description: "Supplier Products are used a step before a full product is created. Supplier products can be very minimal and there can be a lot of them before some are chosen and converted to full Products with all the attributes and such" },
        { title: "Manufacturers", url: "/products/manufacturers", description: "Every Product need a Manufacturer " },
        { title: "Images and other Resources", url: "/products/resources", description: "Images as well as pdf's containing product manuals and such are all so called resources. These can be created and added in the Sales Items Create-view but they can also be viwed and managed from this page. " },
    ];
    return (
        <>
                {pageLinks.map((pageLink, i) => (
                    <ContentCard key={i} {...{cardId:pageLink.title, contentPadding:1, md:3, actionButtons:[{
                        title: "",
                        icon: <LaunchTwoToneIcon />,
                        actionUrl: pageLink.url,
                        variant: 'outlined',
                        iconButton: true,
                    }] }}>
                    <Grid  item sx={{height:200}} px={2} textAlign={'justify'}>
                    <Scrollbar>
                        <Grid item  textAlign={'justify'}>
                            <p >{pageLink.description}</p>
                        </Grid>
                        </Scrollbar>
                    </Grid>
                    </ContentCard>
                ))}
        </>
    );
}



function ProductsHomePage() {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <PageHeader  {...pageHeaderData} />
            <ContentCardsContainer >
                
            <CardSectionHeader contentCard={{cardId:"Page links" } }></CardSectionHeader>
            
                    <PageLinksContent2></PageLinksContent2>
                

                <ContentCard {...{ cardId: "Product and Sales Items Settings", cardSubTitle: "These are the settings from the Webclient Settings page that concern Prodcuts and Sales Items" }}>
                    <h3>Dense Padding - allows more rows in views</h3>
                    <ToggleDensePadding></ToggleDensePadding>
                    <h3>Product Table Columns</h3>
                    <span>Coming soon - (table column settins need to get columns from redux as fallback)</span>
                </ContentCard>


                <ContentCard {...{ cardId: "Page links2" }}>


                    <Box
                        sx={{
                            height: 350
                        }}
                    >
                        <Scrollbar>
                            <List disablePadding>
                                <ListItem
                                    sx={{
                                        p: 2.5
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            mr: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            minWidth: 0
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                background: 'transparent',
                                                color: `${theme.colors.primary.main}`,
                                                border: `${theme.colors.primary.main} solid 2px`,
                                                width: 58,
                                                height: 58
                                            }}
                                        >
                                            <OndemandVideoTwoToneIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                gutterBottom
                                                variant="h4"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {t('Business')}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography noWrap variant="subtitle2">
                                                {t('Grow familiar with the countless')}
                                            </Typography>
                                        }
                                    />
                                    <IconButtonWrapper color="primary">
                                        <ArrowForwardTwoToneIcon fontSize="small" />
                                    </IconButtonWrapper>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        p: 2.5
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            mr: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            minWidth: 0
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                background: 'transparent',
                                                color: `${theme.colors.primary.main}`,
                                                border: `${theme.colors.primary.main} solid 2px`,
                                                width: 58,
                                                height: 58
                                            }}
                                        >
                                            <AutoAwesomeMosaicTwoToneIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                gutterBottom
                                                variant="h4"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {t('Finance')}
                                                <LabelWrapper
                                                    ml={1}
                                                    component="span"
                                                    sx={{
                                                        background: `${alpha(theme.colors.info.light, 0.2)}`,
                                                        color: `${theme.colors.info.main}`
                                                    }}
                                                >
                                                    {t('New')}
                                                </LabelWrapper>
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography noWrap variant="subtitle2">
                                                {t('A wonderful serenity has taken')}
                                            </Typography>
                                        }
                                    />
                                    <IconButtonWrapper color="primary">
                                        <ArrowForwardTwoToneIcon fontSize="small" />
                                    </IconButtonWrapper>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        p: 2.5
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            mr: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            minWidth: 0
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                background: 'transparent',
                                                color: `${theme.colors.primary.main}`,
                                                border: `${theme.colors.primary.main} solid 2px`,
                                                width: 58,
                                                height: 58
                                            }}
                                        >
                                            <EvStationTwoToneIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                gutterBottom
                                                variant="h4"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {t('Images')}
                                                <LabelWrapper
                                                    ml={1}
                                                    component="span"
                                                    sx={{
                                                        background: `${alpha(theme.colors.error.light, 0.2)}`,
                                                        color: `${theme.colors.error.main}`
                                                    }}
                                                >
                                                    {t('Hot')}
                                                </LabelWrapper>
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography noWrap variant="subtitle2">
                                                {t('Absorbed in the exquisite sense')}
                                            </Typography>
                                        }
                                    />
                                    <IconButtonWrapper color="primary">
                                        <ArrowForwardTwoToneIcon fontSize="small" />
                                    </IconButtonWrapper>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        p: 2.5
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            mr: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            minWidth: 0
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                background: 'transparent',
                                                color: `${theme.colors.primary.main}`,
                                                border: `${theme.colors.primary.main} solid 2px`,
                                                width: 58,
                                                height: 58
                                            }}
                                        >
                                            <EvStationTwoToneIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                gutterBottom
                                                variant="h4"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {t('Finance')}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography noWrap variant="subtitle2">
                                                {t('A wonderful serenity has taken')}
                                            </Typography>
                                        }
                                    />
                                    <IconButtonWrapper color="primary">
                                        <ArrowForwardTwoToneIcon fontSize="small" />
                                    </IconButtonWrapper>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        p: 2.5
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            mr: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            minWidth: 0
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                background: 'transparent',
                                                color: `${theme.colors.primary.main}`,
                                                border: `${theme.colors.primary.main} solid 2px`,
                                                width: 58,
                                                height: 58
                                            }}
                                        >
                                            <ContactPhoneTwoToneIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                gutterBottom
                                                variant="h4"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {t('Accounts')}
                                                <LabelWrapper
                                                    ml={1}
                                                    component="span"
                                                    sx={{
                                                        background: `${alpha(theme.colors.warning.light, 0.2)}`,
                                                        color: `${theme.colors.warning.main}`
                                                    }}
                                                >
                                                    {t('In progress')}
                                                </LabelWrapper>
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography noWrap variant="subtitle2">
                                                {t('Absorbed in the exquisite sense')}
                                            </Typography>
                                        }
                                    />
                                    <IconButtonWrapper color="primary">
                                        <ArrowForwardTwoToneIcon fontSize="small" />
                                    </IconButtonWrapper>
                                </ListItem>
                            </List>
                        </Scrollbar>
                    </Box>

                </ContentCard>


               
            </ContentCardsContainer>

            <Footer />
        </>
    );
}

export default ProductsHomePage;
