import { mock } from 'src/utils/axios';
import { PurchaseOrder } from 'src/models/purchase_order';

let purchaseOrders: PurchaseOrder[] = [
  {
    "status": "Completed",
    "order_no": 3003225,
    "type": "Purchase",
    "supplier": "Legle",
    "order_value_sek": 2480,
    "order_value": 2000,
    "currency": "DKK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 2480,
    "sent": "2022-08-24 11:00",
    "completed": "2022-08-24 11:13",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-08-24 10:59"
  },
  {
    "status": "Completed",
    "order_no": 3003224,
    "type": "Purchase",
    "supplier": "Konsthantverk",
    "order_value_sek": 2000,
    "order_value": 2000,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 2000,
    "sent": "2022-07-25 11:42",
    "completed": "2022-07-25 11:45",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-07-25 11:35"
  },
  {
    "status": "Completed",
    "order_no": 3003223,
    "type": "Purchase",
    "supplier": "Chelsea Textiles",
    "order_value_sek": 1267425,
    "order_value": 107500,
    "currency": "GBP",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 1267425,
    "sent": "2022-07-01 14:48",
    "completed": "2022-07-01 14:49",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-07-01 14:48"
  },
  {
    "status": "Completed",
    "order_no": 3003222,
    "type": "Purchase",
    "supplier": "TON",
    "order_value_sek": 165277.72,
    "order_value": 17926,
    "currency": "EUR",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 165277.72,
    "sent": "2022-07-01 14:34",
    "completed": "2022-07-01 14:34",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-07-01 14:34"
  },
  {
    "status": "Completed",
    "order_no": 3003221,
    "type": "Purchase",
    "supplier": "Manglam Arts",
    "order_value_sek": 247355,
    "order_value": 30500,
    "currency": "USD",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 247355,
    "sent": "2022-07-01 14:16",
    "completed": "2022-07-01 14:20",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-07-01 14:15"
  },
  {
    "status": "Completed",
    "order_no": 3003220,
    "type": "Purchase",
    "supplier": "Annuzza",
    "order_value_sek": 49500,
    "order_value": 49500,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 49500,
    "sent": "2022-06-29 15:00",
    "completed": "2022-06-29 15:01",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-06-29 15:00"
  },
  {
    "status": "Completed",
    "order_no": 3003219,
    "type": "Purchase",
    "supplier": "Seletti",
    "order_value_sek": 92900.72,
    "order_value": 10076,
    "currency": "EUR",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 92900.72,
    "sent": "2022-06-20 09:40",
    "completed": "2022-06-20 09:41",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-06-20 09:40"
  },
  {
    "status": "Completed",
    "order_no": 3003218,
    "type": "Purchase",
    "supplier": "Konsthantverk",
    "order_value_sek": 3000,
    "order_value": 3000,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 3000,
    "sent": "2022-05-13 12:04",
    "completed": "2022-05-13 12:05",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-05-13 12:02"
  },
  {
    "status": "Completed",
    "order_no": 3003217,
    "type": "Purchase",
    "supplier": "OSCAR & CLOTHILDE AB",
    "order_value_sek": 100000,
    "order_value": 100000,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 100000,
    "sent": "2022-05-13 11:59",
    "completed": "2022-05-13 12:00",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-05-13 11:59"
  },
  {
    "status": "Completed",
    "order_no": 3003216,
    "type": "Purchase",
    "supplier": "YALONG  CERAMICS  LIMITED",
    "order_value_sek": 2334.06,
    "order_value": 287.8,
    "currency": "USD",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 2334.06,
    "sent": "2022-05-11 13:11",
    "completed": "2022-05-11 13:22",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-05-11 13:07"
  },
  {
    "status": "Completed",
    "order_no": 3003215,
    "type": "Purchase",
    "supplier": "Seletti",
    "order_value_sek": 13553.4,
    "order_value": 1470,
    "currency": "EUR",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 13553.4,
    "sent": "2022-05-06 13:38",
    "completed": "2022-05-06 13:39",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-05-06 13:38"
  },
  {
    "status": "Completed",
    "order_no": 3003214,
    "type": "Purchase",
    "supplier": "OSCAR & CLOTHILDE AB",
    "order_value_sek": 90000,
    "order_value": 90000,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 90000,
    "sent": "2022-05-06 13:38",
    "completed": "2022-05-06 13:39",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-05-06 13:38"
  },
  {
    "status": "Completed",
    "order_no": 3003213,
    "type": "Purchase",
    "supplier": "OSCAR & CLOTHILDE AB",
    "order_value_sek": 100000,
    "order_value": 100000,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 100000,
    "sent": "2022-05-06 08:42",
    "completed": "2022-05-06 08:43",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-05-06 08:42"
  },
  {
    "status": "Completed",
    "order_no": 3003212,
    "type": "Purchase",
    "supplier": "Bergs Potter",
    "order_value_sek": 620,
    "order_value": 620,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 620,
    "sent": "2022-05-06 08:31",
    "completed": "2022-05-06 13:41",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-05-06 08:30"
  },
  {
    "status": "Completed",
    "order_no": 3003211,
    "type": "Purchase",
    "supplier": "YALONG  CERAMICS  LIMITED",
    "order_value_sek": 1167.03,
    "order_value": 143.9,
    "currency": "USD",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 1167.03,
    "sent": "2022-03-23 10:52",
    "completed": "2022-03-23 10:55",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-03-23 10:52"
  },
  {
    "status": "Completed",
    "order_no": 3003210,
    "type": "Purchase",
    "supplier": "JLC",
    "order_value_sek": 33238.1,
    "order_value": 3605,
    "currency": "EUR",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 33238.1,
    "sent": "2022-03-10 11:02",
    "completed": "2022-03-10 11:03",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-03-10 11:02"
  },
  {
    "status": "Completed",
    "order_no": 3003208,
    "type": "Purchase",
    "supplier": "John Derian",
    "order_value_sek": 1622,
    "order_value": 200,
    "currency": "USD",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 1622,
    "sent": "2022-02-17 13:54",
    "completed": "2022-02-17 13:56",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-02-17 13:54"
  },
  {
    "status": "Completed",
    "order_no": 3003207,
    "type": "Purchase",
    "supplier": "Bergo Lampskärma",
    "order_value_sek": 2960,
    "order_value": 2960,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 2960,
    "sent": "2022-02-14 12:22",
    "completed": "2022-07-13 10:36",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-02-14 12:22"
  },
  {
    "status": "Shipped",
    "order_no": 3003206,
    "type": "Purchase",
    "supplier": "Bergo Lampskärma",
    "order_value_sek": 592,
    "order_value": 592,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 592,
    "sent": "2022-02-14 12:13",
    "completed": "",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-02-14 12:13"
  },
  {
    "status": "Shipped",
    "order_no": 3003205,
    "type": "Purchase",
    "supplier": "Bergo Lampskärma",
    "order_value_sek": 592,
    "order_value": 592,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 592,
    "sent": "2022-02-14 11:59",
    "completed": "",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-02-14 11:59"
  },
  {
    "status": "Shipped",
    "order_no": 3003204,
    "type": "Purchase",
    "supplier": "Bergo Lampskärma",
    "order_value_sek": 592,
    "order_value": 592,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 592,
    "sent": "2022-02-14 11:58",
    "completed": "",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-02-14 11:58"
  },
  {
    "status": "Shipped",
    "order_no": 3003203,
    "type": "Purchase",
    "supplier": "Bergo Lampskärma",
    "order_value_sek": 592,
    "order_value": 592,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 592,
    "sent": "2022-02-14 11:57",
    "completed": "",
    "our_contact": "Jonas Andersson",
    "create_date": "2022-02-14 11:57"
  },
  {
    "status": "Completed",
    "order_no": 3003202,
    "type": "Purchase",
    "supplier": "Epok",
    "order_value_sek": 5400,
    "order_value": 5400,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 5400,
    "sent": "2022-01-10 17:00",
    "completed": "2022-01-10 17:02",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-01-10 16:59"
  },
  {
    "status": "Completed",
    "order_no": 3003201,
    "type": "Purchase",
    "supplier": "Bergs Potter",
    "order_value_sek": 1600,
    "order_value": 1600,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 1600,
    "sent": "2022-01-10 15:14",
    "completed": "2022-01-10 15:17",
    "our_contact": "Erik Ekblad",
    "create_date": "2022-01-10 15:13"
  },
  {
    "status": "Completed",
    "order_no": 3003200,
    "type": "Purchase",
    "supplier": "Filipiniana",
    "order_value_sek": 1000,
    "order_value": 1000,
    "currency": "SEK",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 1000,
    "sent": "2021-12-13 11:22",
    "completed": "2021-12-13 11:27",
    "our_contact": "Erik Ekblad",
    "create_date": "2021-12-13 11:22"
  },
  {
    "status": "Received Short",
    "order_no": 3003199,
    "type": "Purchase",
    "supplier": "YALONG  CERAMICS  LIMITED",
    "order_value_sek": 598.03,
    "order_value": 73.74,
    "currency": "USD",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 598.03,
    "sent": "2021-10-18 15:57",
    "completed": "2021-10-18 16:04",
    "our_contact": "Erik Ekblad",
    "create_date": "2021-10-18 09:50"
  },
  {
    "status": "Completed",
    "order_no": 3003198,
    "type": "Purchase",
    "supplier": "YALONG  CERAMICS  LIMITED",
    "order_value_sek": 82.64,
    "order_value": 10.19,
    "currency": "USD",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 82.64,
    "sent": "2021-10-15 12:48",
    "completed": "2021-10-15 12:48",
    "our_contact": "Erik Ekblad",
    "create_date": "2021-10-15 12:47"
  },
  {
    "status": "Completed",
    "order_no": 3003197,
    "type": "Purchase",
    "supplier": "YALONG  CERAMICS  LIMITED",
    "order_value_sek": 583.51,
    "order_value": 71.95,
    "currency": "USD",
    "misc_charges_sek": 0,
    "order_value_mc_sek": 583.51,
    "sent": "2021-10-15 12:39",
    "completed": "2021-10-15 12:43",
    "our_contact": "Erik Ekblad",
    "create_date": "2021-10-15 12:37"
  }
]

mock.onGet('/api/purchase-orders').reply(() => {
  return [200, { purchaseOrders }];
});

mock.onGet('/api/purchase-order').reply((config) => {
  const  orderId  =  parseInt(config.params.params.order_no);
  const order = purchaseOrders.find((_order) => _order.order_no === orderId);
  return [200, { order }];
});

