import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Molecules/Authenticated/Authenticated';
import { Navigate } from 'react-router-dom';


import DocsLayout from 'src/components/Organisms/SidebarMenu/DocsLayout';
import BaseLayout from 'src/components/Organisms/SidebarMenu/BaseLayout';
import AccentSidebarLayout from 'src/components/Organisms/SidebarMenu/AccentSidebarLayout';
import dashboardsRoutes from './dashboards';
import blocksRoutes from './blocks';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import documentationRoutes from './documentation';
import accountRoutes from './account';
import baseRoutes from './base';
import pagesRoutes from './pages';

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },

  // Documentation

  {
    path: 'docs',
    element: <DocsLayout />,
    children: documentationRoutes
  },

  // Boxed Sidebar Layout

 

  {
    path: '',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        children: pagesRoutes
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  }
];

export default router;
