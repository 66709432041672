import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
export interface OneTreeItemState{
  id: string;
  selected?: boolean;
}
export interface OneTreeState{
  treeItemStates: OneTreeItemState[];
  id: string;
}
interface TreesState {
  trees: OneTreeState[];
}
const initialState: TreesState = {
  trees: [{treeItemStates:[], id:""}]
};

const slice = createSlice({
  name: 'trees',
  initialState,
  reducers: {
    toggleTreeItemSelected(state: TreesState,action) {
      if( state.trees.some(tree => tree.id == action.payload.id)){
        // table with id exist
        if(state.trees.find(tree => tree.id == action.payload.id).treeItemStates.some(treeItem => treeItem.id == action.payload.nodeId)){
          // column in that table exist
          //toggle state
          state.trees.find(tree => tree.id == action.payload.id).treeItemStates.find(treeItem => treeItem.id == action.payload.nodeId).selected = 
          !state.trees.find(tree => tree.id == action.payload.id).treeItemStates.find(treeItem => treeItem.id == action.payload.nodeId).selected;
        }
     }
    },
    setSelectedTreeItems(state:TreesState, action){
      if( state.trees.some(tree => tree.id == action.payload.id)){
        // table with id exist
        state.trees.find(tree => tree.id == action.payload.id).treeItemStates.forEach(treeItemState => {
          if( action.payload.nodeIds.indexOf(treeItemState.id) > -1){
            state.trees.find(tree => tree.id == action.payload.id).treeItemStates.find(treeItem => treeItem.id ==treeItemState.id).selected = true;
          }else{
            state.trees.find(tree => tree.id == action.payload.id).treeItemStates.find(treeItem => treeItem.id ==treeItemState.id).selected = false;
          }
      });
     }
    },
    addTree(state: TreesState,action) {
      if(state.trees.some(table => table.id == action.payload.id)){
        //console.log(`table with ${action.payload.id} id exist`);
      }else{
        state.trees.push(action.payload);
      }
    },
    setNewTreeItems(state: TreesState,action) {
      if(state.trees.some(tree => tree.id == action.payload.id)){
        state.trees.find(tree => tree.id == action.payload.id).treeItemStates = action.payload.treeItemStates;        
      }else{
        addTree(action.payload);
      }
    }
  }
});
export const reducer = slice.reducer;

export const addTree = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.addTree(input));
};
export const setSelectedTreeItems = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.setSelectedTreeItems(input));
};
export const setNewTreeItems = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.setNewTreeItems(input));
};
export const toggleTreeItemSelected = (input): AppThunk => (dispatch) => {
  dispatch(slice.actions.toggleTreeItemSelected(input));
};
export default slice;
