import { Link, useLocation } from 'react-router-dom';
import { Grid, Typography, Breadcrumbs, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/Molecules/PageHeader/PageTitleWrapper';
import { useTranslation } from 'react-i18next';
import AdditionalActions from '../Atoms/Buttons/AdditionalActions';
import TooltipField from '../Atoms/TooltipField';
import { customComponent } from 'src/interfaces/customComponent';
import { separateMenuComponents } from 'src/utils/buttons/separateMenuComponents';
import useBreadcrumbs from 'src/hooks/PageHeader/useBreadcrumbs';
import { CustomAlert, CustomAlertProps } from '../Atoms/CustomAlert';

export interface PageHeaderProps {
  title: string;
  subTitle?: string;
  tooltipText?: string;
  components?: customComponent[];
  alertData?: CustomAlertProps;
}

const PageHeader = (pageHeaderData: PageHeaderProps) => {
  const { t }: { t: any } = useTranslation();
  const breadcrumbs = useBreadcrumbs();
  const [TopRightComponents, MenuItemsComponents] = separateMenuComponents(pageHeaderData.components);
  return (
    <>
      <Helmet>
        <title>{pageHeaderData.title}</title>
      </Helmet>
      <PageTitleWrapper >
        <Grid container columnSpacing={2} justifyContent="space-between" alignItems="center">
          <Grid item sm={12} md={true}>
            <Typography variant="h3" component="h3" gutterBottom>
              {pageHeaderData.title}
              <TooltipField tooltipText={pageHeaderData.tooltipText}></TooltipField>
            </Typography>
            <Typography variant="subtitle2">
              {pageHeaderData.subTitle}
            </Typography>
            <Box>
              <Breadcrumbs maxItems={5} aria-label="breadcrumb">
                {breadcrumbs.path.map((path, i) => {
                  return (
                    <Link key={"link" + i} color="inherit" to={path} >
                      {t(breadcrumbs.label[i])}
                    </Link>
                  )
                }
                )}
              </Breadcrumbs>
            </Box>
          </Grid>
          <Box display="flex" justifyContent="right"
            sx={{
              width: { xs: "100%", md: "initial" },
            }} >
            {TopRightComponents?.map((customComponent, i) => (
              <Grid 
              pl={1} 
              key={i} item  
              width="100%"
              sx={{
                width: { xs: "100%", sm: "initial" },
                justifyContent: { xs: "center", sm: "right" }
              }} >
                {customComponent.component}
              </Grid>
            ))}
            <AdditionalActions >
              {MenuItemsComponents?.length > 0 ? MenuItemsComponents?.map((customComponent, i) => (
                <Grid key={i} item xs={2}>
                  {customComponent.component}
                </Grid>
              )) : null}
            </AdditionalActions>
          </Box>
          <Grid item md={12} marginTop={2}>
            <CustomAlert {...pageHeaderData.alertData} ></CustomAlert>
            </Grid>  
        </Grid>
        
        
      </PageTitleWrapper>
    </>
  );
}

export default PageHeader;