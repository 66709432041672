export enum DataSourceIndex {
  Bold = 0,
  Ongoing = 1,
  Magento = 2,
  Sitoo = 3,
  Vendre = 4
}

export enum OrderChannelTypeEnum {
  Direct = 1,
  Web = 2,
  Store = 3,
  ShopInShop = 4,
  Fair = 5
}